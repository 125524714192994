import React, { Fragment, useEffect } from 'react';
import { Row, Select } from 'antd';
import NewFeatureResearchSelectLists from './NewFeatureResearchSelectLists';
import {
  IResearchDataCheckState,
  IResearchDataState,
} from '../../../../models/interfaces';
import { useFeatureSets } from '../../../../stores/newFeatureSets.store';
import {
  useResearchDataCheckStore,
  useResearchDataHandlingStore,
} from '../../../../stores';

const NewFeatureSets = () => {
  type dropDownAddButton = {
    value: number;
    label: string;
    order_id: number;
  };

  let valueCounter = 9;
  const valuesForDropDownr: dropDownAddButton[] = [
    { label: 'Diagnosis', order_id: 8, value: 'Diagnosis' },
    { label: 'Drug', order_id: 9, value: 'Drug' },
    { label: 'Procedure', order_id: 10, value: 'Procedure' },
    { label: 'Generic Drug', order_id: 11, value: 'Generic Drug' },
  ].map((item) => ({
    ...item,
    value: valueCounter++,
  }));

  const { updatedDataSet } = useResearchDataHandlingStore(
    (state: IResearchDataState) => state
  );
  const { ifUpdated } = useResearchDataCheckStore(
    (state: IResearchDataCheckState) => state
  );

  const {
    featureSetMasterArray,
    setFeatureSetMasterArray,
    setIsResearchFeatureSetdeleted,
    isResearchFeatureSetdeleted,
    addedTargeting,
    setAddedTargeting,
  } = useFeatureSets((state: any) => state);

  const handleSelectChange = (selectedValue: dropDownAddButton) => {
    setAddedTargeting([selectedValue]);
    setIsResearchFeatureSetdeleted(false);
  };

  useEffect(() => {
    const rsearchSelectListObj = [
      {
        setId: 9,
        filter_id: 0,
        id: '',
        label: 'Diagnosis',
        placeholder: 'Select Diagnosis',
        esParams: JSON.parse(
          process.env?.REACT_APP_ES_DIAGNOSIS_PARAMS ?? '{}'
        ),
        value: '',
        operatorValue: 5,
        isError: false,
      },
      {
        setId: 10,
        filter_id: 0,
        id: '',
        label: 'Drug',
        placeholder: 'Select Drugs',
        esParams: JSON.parse(process.env?.REACT_APP_ES_DRUG_PARAMS ?? '{}'),
        value: '',
        operatorValue: 5,
        isError: false,
      },
      {
        setId: 11,
        filter_id: 0,
        id: '',
        label: 'Procedure',
        placeholder: 'Select Procedures',
        esParams: JSON.parse(
          process.env?.REACT_APP_ES_PROCEDURE_PARAMS ?? '{}'
        ),
        value: '',
        operatorValue: 5,
        isError: false,
      },
      {
        setId: 12,
        filter_id: 0,
        id: '',
        label: 'Generic Drug',
        placeholder: 'Select generic drug',
        esParams: JSON.parse(
          process.env?.REACT_APP_ES_DRUG_GENERIC_PARAMS ?? '{}'
        ),
        value: '',
        operatorValue: 5,
        isError: false,
      },
    ];

    if (addedTargeting.length > 0 && !isResearchFeatureSetdeleted) {
      const lastAddedTarget = addedTargeting[addedTargeting.length - 1];
      const matchingObject = rsearchSelectListObj.find(
        (obj: any) => obj.setId === lastAddedTarget
      );
      if (matchingObject) {
        setFeatureSetMasterArray([
          ...featureSetMasterArray,
          { ...matchingObject, id: `${matchingObject.setId}_${matchingObject.filter_id}` },
        ]);
      }
    }
  }, [addedTargeting]);
  
  useEffect(() => {
    if (updatedDataSet?.filter_data && ifUpdated) {
      setIsResearchFeatureSetdeleted(true);
      const tempMasterArray: any = [];
      const tempTargettingArray: any = [];
      updatedDataSet?.filter_data?.map((e: any) => {
        tempTargettingArray.push(e.targeting_id);
        tempMasterArray.push({
          setId: e.targeting_id,
          filter_id: e.filter_id,
          label:
            e.targeting_id === 9
              ? 'Diagnosis'
              : e.targeting_id === 10
              ? 'Drug'
              : e.targeting_id === 11
              ? 'Procedure'
              : 'Generic Drug',
          placeholder:
            e.targeting_id === 9
              ? 'Select Diagnosis'
              : e.targeting_id === 10
              ? 'Select Drugs'
              : e.targeting_id === 11
              ? 'Select Procedures'
              : 'Select generic drug',
          esParams: JSON.parse(
            e.targeting_id === 9
              ? process.env?.REACT_APP_ES_DIAGNOSIS_PARAMS ?? '{}'
              : e.targeting_id === 10
              ? process.env?.REACT_APP_ES_DRUG_PARAMS ?? '{}'
              : e.targeting_id === 11
              ? process.env?.REACT_APP_ES_PROCEDURE_PARAMS ?? '{}'
              : process.env?.REACT_APP_ES_DRUG_GENERIC_PARAMS ?? '{}'
          ),
          value: JSON.parse(e.tergeting_value),
          operatorValue: e.operator_id,
          isError: false,
        });
      });
      setFeatureSetMasterArray(tempMasterArray);
      setAddedTargeting(tempTargettingArray);
    }
  }, [updatedDataSet]);

  return (
    <div className="createResearchElementsWrap">
        <div className="sectionHeader">
          <div className="addResearchElemHeader">
            <h2 className="pageHeader">
              Research Target<span className="isMandatory">*</span>
            </h2>{' '}
          </div>
        </div>
        <div className="targetingElementWrap createResearchElementWrap">
            {!featureSetMasterArray?.length ? (
              <div className="noTargetingFeatureSet">
                Begin creating this Research filter by selecting a filter
              </div>
            ) : (
              <>
                {featureSetMasterArray?.map((featureSet: any, index: number) => (
                  <Row className="formRow" key={index}>
                    <NewFeatureResearchSelectLists
                      setId={featureSet.setId}
                      label={featureSet.label}
                      index={index}
                      placeholder={featureSet.placeholder}
                      esParams={featureSet.esParams}
                      value={featureSet.value}
                      operatorValue={featureSet?.operatorValue?.toString()}
                      ifShowError={featureSet.isError}
                    />
                  </Row>
                ))}
              </>
            )}
          </div>
          <Select
            className="customAntSelect"
            options={valuesForDropDownr}
            placeholder="Select Targetable Feature"
            onChange={handleSelectChange}
            value={null}
          />
      </div>
  );
};

export default NewFeatureSets;
