/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { Select } from 'antd';
import {
  IEachDropdownElementsType,
  IEnableSaveState,
} from '../../../models/interfaces';
import { renderTooltip } from './';
import { useCheckSavingEnabilityStore } from '../../../stores';
import { WarningOutlined } from '@ant-design/icons';

const Multi: FC<{
  options: IEachDropdownElementsType[];
  placeholder: string;
  isSearchable?: boolean;
  componentSpecificClasses?: string[];
  isDisabled?: boolean;
  defaultPopupWidth?: number | boolean;
  defaultPopupClassName?: string;
  allowClear?: boolean;
  props?: any;
  value?: (number | string)[];
  onChange: React.Dispatch<React.SetStateAction<(number | string)[]>>;
  isUpdateInDual?: boolean;
  setIsUpdateInDual?: React.Dispatch<React.SetStateAction<boolean>>;
  targetingSetNumber?: number;
  featureSetNumber?: number;
}> = ({
  options,
  placeholder,
  isSearchable = false,
  componentSpecificClasses,
  isDisabled = false,
  defaultPopupWidth = true,
  defaultPopupClassName = '',
  allowClear = true,
  props = {},
  value,
  onChange,
  isUpdateInDual = false,
  setIsUpdateInDual,
  targetingSetNumber,
  featureSetNumber,
}) => {
  const [ifShowError, setIfShowError] = useState<boolean>(false),
    [selection, setSelection] = useState<any>(null),
    [selectedData, setSelectedData] = useState<any>(undefined);

  const { isFeatureSetsFilled, updateIisFeatureSetsFilled } =
    useCheckSavingEnabilityStore((state: IEnableSaveState) => state);

  const filterOption = (input: string, option?: IEachDropdownElementsType) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onFocus = () => {
    ifShowError &&
      updateIisFeatureSetsFilled(featureSetNumber, targetingSetNumber, 0);
  };
  
  useEffect(() => {
    if (
      featureSetNumber !== undefined &&
      targetingSetNumber !== undefined &&
      isFeatureSetsFilled[`${featureSetNumber}`][`${targetingSetNumber}`] === 1
    ) {
      setIfShowError(true);
      return;
    }
    setIfShowError(false);
  }, [isFeatureSetsFilled]);

  useEffect(() => {
    const initialValue = value ?? props?.defaultValue;
    if (initialValue) {
      setSelection(initialValue);
    }
  }, []);

  useEffect(() => {
    if (value) {
      if (value.length <= 0) {
        setSelection(null);
        setSelectedData([]);
      } else {
        setSelectedData(
          options.filter((el: any) => value.includes(Number(el.value)) || value.includes(el.value))
        );
        setSelection(value);
      }
    }
  }, [value]);

  const valueUpdate = (data: any) => {
    setSelectedData(
      options.filter((el: any) => data.includes(Number(el.value)) || data.includes(el.value))
    );
    setSelection(data);
    onChange(data);
  };

  return (
    <div className="eachCommonElementWrapper">
      <Select
        mode="multiple"
        showSearch={isSearchable}
        placeholder={placeholder}
        optionFilterProp="children"
        value={selectedData}
        onChange={valueUpdate}
        filterOption={filterOption}
        options={options.filter((el) => !selection?.includes(el.value))}
        className={`multiSelectDropdown${
          componentSpecificClasses?.length
            ? ' ' + componentSpecificClasses.join(' ')
            : ''
        }`}
        disabled={isDisabled}
        allowClear={allowClear}
        popupMatchSelectWidth={defaultPopupWidth}
        popupClassName={defaultPopupClassName}
        maxTagPlaceholder={renderTooltip}
        onFocus={onFocus}
        status={ifShowError ? 'error' : ''}
        suffixIcon={
          ifShowError ? <WarningOutlined className="validationErrorIcon" /> : ''
        }
        {...props}
      />
    </div>
  );
};

export default Multi;
