import React, { Fragment, useEffect, useState } from 'react';
import { PageHeader } from '../../../sharedComponent';
import {
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useLoaderStore,
  // useMasterAdditionalOptionElementsStore,
  useMasterAudienceTypeElementsStore,
} from '../../../../stores';
import {
  IAudienceSetUpState,
  // IAudienceState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IFeatureSetElement,
  ILoaderState,
  // IMasterAdditionalOptionDDState,
  IMasterAdvertiserDDState,
  IMasterAgencyDDState,
  IMasterAudienceTypeDDState,
  IMasterBrandDDState,
} from '../../../../models/interfaces';
import {
  CareGiverFeatureSet,
  DefaultFeatureSet,
  DefinedFeatureSet,
  GlobalFeatureSet,
  HcpFeatureSet,
} from '../../AudienceSetup/AudienceSetupComponents';
import { useAudienceSetUp } from '../../../../stores/audienceSets.store';
import { Button } from 'antd';
import {
  useNotificationMessage,
  usePostApiInterceptor,
  useRedirection,
} from '../../../../hooks';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import {
  useMasterAdvertiserElmentStore,
  useMasterAgencyElmentStore,
  useMasterBrandElmentStore,
} from '../../../../stores/masterData.store';
// import { ai } from '../../../../assets/images';
// import {
//   Area,
//   AreaChart,
//   CartesianGrid,
//   Cell,
//   Legend,
//   Pie,
//   PieChart,
//   ResponsiveContainer,
//   XAxis,
//   YAxis,
// } from 'recharts';

// const COLORS = ['#B1D1B7', '#C05F52', '#3170EC'];

// const LineData = [
//   {
//     name: '1',
//     pv: 1,
//     amt: 0,
//   },
//   {
//     name: '2',
//     pv: 3,
//     amt: 3,
//   },
//   {
//     name: '3',
//     pv: 2,
//     amt: 2,
//   },
//   {
//     name: '4',
//     pv: 4,
//     amt: 4,
//   },
//   {
//     name: '5',
//     pv: 5,
//     amt: 4,
//   },
// ];

// const AreaData = [
//   {
//     name: 'Page A',
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: 'Page B',
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: 'Page C',
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: 'Page D',
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: 'Page E',
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: 'Page F',
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: 'Page G',
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];

// const PieData = [
//   { name: 'Group A', value: 400 },
//   { name: 'Group B', value: 300 },
//   { name: 'Group C', value: 300 },
// ];

function separateFeatureSetsByTargetingId(data: any): {
  withTargetingId: any[];
  withoutTargetingId: any[];
  withCaregiver: any;
  withHcp: any;
} {
  const withTargetingId: any[] = [];
  const withoutTargetingId: any[] = [];
  let withCaregiver: any = null;
  let withHcp: any = null;

  data.forEach((featureSet: any) => {
    if (
      featureSet.targeting.some(
        (target: any) =>
          target.targeting_id === 1 ||
          target.targeting_id === 2 ||
          target.targeting_id === 3 ||
          target.targeting_id === 4 ||
          target.targeting_id === 5 ||
          target.targeting_id === 15 ||
          target.targeting_id === 19
      )
    ) {
      withTargetingId.push(featureSet);
    } else if (featureSet.is_caregiver) {
      withCaregiver = featureSet;
    } else if (featureSet.is_hcp) {
      withHcp = featureSet;
    } else {
      withoutTargetingId.push(featureSet);
    }
  });

  return { withTargetingId, withoutTargetingId, withCaregiver, withHcp };
}

const AudienceWrap = () => {
  const { loaderState } = useLoaderStore((state: ILoaderState) => state),
    { audienceTypeDDElements } = useMasterAudienceTypeElementsStore(
      (state: IMasterAudienceTypeDDState) => state
    ),
    { ifUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    { updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { agencyDDElements } = useMasterAgencyElmentStore(
      (state: IMasterAgencyDDState) => state
    ),
    { brandDDElements } = useMasterBrandElmentStore(
      (state: IMasterBrandDDState) => state
    ),
    { advertiserDDElements } = useMasterAdvertiserElmentStore(
      (state: IMasterAdvertiserDDState) => state
    ),
    { redirectAudienceListing } = useRedirection(),
    // { audienceSummary, setAudienceSummary } = useAudienceSets(
    //   (state: IAudienceState) => state
    // ),
    {
      audienceTitle,
      audienceBrand,
      audienceAgency,
      setAudienceAgency,
      setAudiencebrand,
      setAudienceTitle,
      advertiser,
      setAdvertiser,
    } = useAudienceSetUp((state: IAudienceSetUpState) => state);

  const [selectedAudienceType, setSelectedAudienceType] = useState<
      number | null
    >(null),
    [selectedAdditionalOpts, setSelectedAdditionalOpts] =
      useState<boolean>(false),
    [careGiverSet, setCareGiverSet] = useState<IFeatureSetElement>({
      setId: 0,
      orderId: 0,
    }),
    [hcpFeatureSet, setHcpFeatureSet] = useState<IFeatureSetElement>({
      setId: 0,
      orderId: 0,
    }),
    [body, setBody] = useState<any>(null),
    [prevSetId, setPrevSetId] = useState<number>(0),
    [featureSetCount, setFeatureSetCount] = useState<number>(1),
    [featureSets, setFeatureSets] = useState<IFeatureSetElement[]>([]),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false }),
    [organisationType, setOrganisationType] = useState<string | null>(null),
    [globalFeatureSets, setGlobalFeatureSets] = useState<IFeatureSetElement>({
      setId: 0,
      orderId: 0,
    });

  useEffect(() => {
    return () => {
      setAudienceTitle('');
      setAudiencebrand(null);
      setAdvertiser(null);
      setAudienceAgency(null);
      setFeatureSets([]);
      setFeatureSetCount(1);
      setSelectedAudienceType(null);
      // redirectAudienceListing();
    };
  }, []);

  const { context, destroyMessage } = useNotificationMessage(messageObj);

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.SAVE_FEATURE_SET_DATA,
    (data: any, error: any) => {
      setBody('');
      setIsMounted(false);
      if (data && !error) {
        redirectAudienceListing();
        updateErrorMessage('success', 'Audience successful');
      } else {
        updateErrorMessage('error', 'Error occurred while saving Audience.');
      }
    }
  );

  const updateErrorMessage = (type: string, messagecontent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messagecontent,
      duration: 5,
    });
  };

  useEffect(() => {
    if (ifUpdated) {
      const {
        title,
        audience_type_id,
        additional_option_id,
        agency_id: audience_agency,
        brand_id: audience_brand,
        advertiser_id: advertiser,
        feature_sets,
      } = updatedDataSet;
      setAudienceAgency(audience_agency);
      setAudiencebrand(audience_brand);
      setAudienceTitle(title);
      setAdvertiser(advertiser);
      // setAudienceSummary(
      //   feature_sets.length && feature_sets[0].feature_set_summary
      // );
      setSelectedAudienceType(audience_type_id);
      setSelectedAdditionalOpts(additional_option_id);

      const { withTargetingId, withoutTargetingId, withCaregiver, withHcp } =
        separateFeatureSetsByTargetingId(feature_sets);
      if (feature_sets?.length) {
        const newFeatureSets: IFeatureSetElement[] = [];
        let largestSetId = 0;
        withoutTargetingId
          .sort((a: any, b: any) => a.feature_set_rank - b.feature_set_rank)
          .forEach((el: any, index: number) => {
            if (el.feature_set_id > largestSetId) {
              largestSetId = el.feature_set_id;
            }
            newFeatureSets.push({
              setId: el.feature_set_id,
              orderId: index + 1,
            });
          });
        setPrevSetId(largestSetId + 1);
        setFeatureSetCount(withoutTargetingId.length);
        if (withHcp) {
          setHcpFeatureSet({
            setId: withHcp.feature_set_id,
            orderId: 1,
            element: createHcpElem(withHcp.feature_set_id, 1),
          });
        }
        if (withTargetingId?.length) {
          setGlobalFeatureSets({
            setId: withTargetingId.length
              ? withTargetingId[0].feature_set_id
              : 1,
            orderId: 1,
            element: createGlobalElem(
              withTargetingId.length ? withTargetingId[0].feature_set_id : 1,
              1
            ),
          });
        }
        setFeatureSets(
          createFeatureSet(newFeatureSets, withoutTargetingId.length)
        );
        if (withCaregiver && additional_option_id) {
          setCareGiverSet({
            setId: withCaregiver.feature_set_id,
            orderId: withCaregiver.order_id,
            element: createCareGiverElem(
              withCaregiver.feature_set_id,
              withCaregiver.order_id
            ),
          });
        }
      }
    }
  }, [ifUpdated]);

  const createHcpElem = (setId: number, orderId: number) => {
    return (
      <HcpFeatureSet
        featureSetNumber={setId}
        featureSetOrderNumber={orderId}
        disabled
      />
    );
  };

  const createFeatureSet = (fs?: IFeatureSetElement[], fsCount?: number) => {
    const tempFeatureSets: IFeatureSetElement[] = fs?.length
        ? [...fs]
        : [...featureSets],
      newFeatureSets: IFeatureSetElement[] = [];

    for (let i = 0; i < (fsCount ?? featureSetCount); i++) {
      if (i < tempFeatureSets.length) {
        const { setId, orderId } = tempFeatureSets[i];
        newFeatureSets.push({
          setId: setId,
          orderId: orderId,
          element: createNewElem(setId, orderId, fsCount),
        });
      } else {
        const newSetId = prevSetId + 1;
        setPrevSetId(newSetId);

        newFeatureSets.push({
          setId: newSetId,
          orderId: i + 1,
          element: createNewElem(newSetId, i + 1),
        });
      }
    }
    return newFeatureSets;
  };

  const createNewElem = (
    setId: number,
    orderId: number,
    setCount: number | null = null
  ) => {
    return (
      <div>
        <DefinedFeatureSet
          featureSetNumber={setId}
          featureSetOrderNumber={orderId}
          disabled={true}
        />
      </div>
    );
  };

  const createCareGiverElem = (setId: number, orderId: number) => {
    return (
      <CareGiverFeatureSet
        featureSetNumber={setId}
        selectedAdditionalOpts={selectedAdditionalOpts}
        disabled={true}
      />
    );
  };

  const handleCancel = () => {
    setAudienceTitle('');
    setAudiencebrand(null);
    setAdvertiser(null);
    setAudienceAgency(null);
    setFeatureSets([]);
    setFeatureSetCount(1);
    setSelectedAudienceType(null);
    redirectAudienceListing();
  };

  const handleSave = () => {
    destroyMessage('save');
    setMessageObj({ isShowing: false });
    const overAllData = {
      audience_id: updatedDataSet.audience_id,
      audience_type_id: updatedDataSet.audience_type_id,
      title: audienceTitle,
      agency_id: audienceAgency,
      brand_id: audienceBrand,
      advertiser_id: advertiser,
      status_id: updatedDataSet.status_id,
      additional_option_id: selectedAdditionalOpts,
      feature_sets: updatedDataSet?.feature_sets?.length
        ? updatedDataSet?.feature_sets
        : [],
      is_edited: 1,
    };
    setBody(overAllData);
    setIsMounted(true);
  };

  const createGlobalElem = (setId: number, orderId: number) => {
    return (
      <GlobalFeatureSet
        featureSetNumber={setId}
        featureSetOrderNumber={orderId}
        selectedAudienceType={selectedAudienceType}
        disabled={true}
      />
    );
  };

  return (
    <>
      <div className="audienceCommonWrap">
        <PageHeader title="View Audience" />
        {context}
        {!loaderState && (
          <>
            <DefaultFeatureSet
              disabled={true}
              defaultTitle={audienceTitle}
              setTitle={setAudienceTitle}
              audienceType={selectedAudienceType}
              setAudienceType={(value: any) => setSelectedAudienceType(value)}
              advertiser={advertiser}
              setAdvertiser={setAdvertiser}
              defaultAgency={audienceAgency}
              setAgency={setAudienceAgency}
              defaultBrand={audienceBrand}
              agencyDDElements={agencyDDElements}
              brandDDElements={brandDDElements}
              advertiserDDElements={advertiserDDElements}
              setBrand={setAudiencebrand}
              selectedAdditionalOpts={selectedAdditionalOpts}
              setSelectedAdditionalOpts={setSelectedAdditionalOpts}
              audienceTypeDDElements={audienceTypeDDElements}
              setOrganisationType={setOrganisationType}
              openOrganisation={() => {}}
            />

            <div className="targetableFeatureAndAudienceInsight">
              <div className="featureSetWhole">
                <div>{globalFeatureSets.element}</div>
                <div>{careGiverSet.element}</div>
                <div>{hcpFeatureSet.element}</div>
                {featureSetCount > 0 &&
                  featureSets.map((el: IFeatureSetElement) => (
                    <Fragment key={el.setId}>{el.element}</Fragment>
                  ))}
              </div>
            </div>

            {/* {audienceSummary && (
              <div className="audienceScreenInfoElemWrap infoElemWrap">
                <div className="chart1">
                  <div className="d-flex">
                    <img src={ai} alt="" />
                    <h2>About this audience</h2>
                  </div>
                  <p>{audienceSummary}</p>
                </div>
              </div>
            )}
            <div className="audienceScreenChartWrap">
            <div className="chartElemWrap">
              <div className="chart1">
                <b>Lorem Ipsum</b>
                <b>
                  1000 <span>lorem</span>
                </b>
                <ResponsiveContainer width="100%" height="80%">
                  <AreaChart
                    data={LineData}
                    syncId="anyId"
                    margin={{
                      top: 10,
                      right: 0,
                      left: -50, // Set left margin to 0
                      bottom: 0,
                    }}
                    className="areaChart1"
                  >
                    <defs>
                      <linearGradient
                        id="myGradient"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                      >
                        <stop offset="0%" stopColor="#DBE7FF" stopOpacity={1} />
                        <stop offset="100%" stopColor="#fff" stopOpacity={1} />
                      </linearGradient>
                    </defs>
                    <CartesianGrid
                      vertical={true}
                      horizontal={false}
                      stroke="#B1B1B1"
                      strokeDasharray="0.5"
                    />
                    <XAxis dataKey="name" axisLine={false} tickLine={false} />{' '}
                    <YAxis />
                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="pv"
                      stroke="#3170EC"
                      fill="url(#myGradient)"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>

              <div className="chart2">
                <ResponsiveContainer width="100%" height="80%">
                  <PieChart>
                    <Pie
                      data={PieData}
                      cx={0}
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                      cornerRadius={10}
                      label={false}
                    >
                      {PieData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Legend
                      layout="vertical"
                      verticalAlign="bottom"
                      align="left"
                      iconType="square"
                      wrapperStyle={{
                        paddingLeft: 20,
                      }}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>

              <div className="chart3">
                <ResponsiveContainer width="100%" height="80%">
                  <AreaChart
                    data={AreaData}
                    margin={{
                      top: 10,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="uv"
                      stroke="#8884d8"
                      fill="#8884d8"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
            </div> */}
          </>
        )}
      </div>
      <div className="newFeatureSetBtn">
        <Button
          type="text"
          size="large"
          onClick={handleCancel}
          className="noBGBtn"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="large"
          disabled={
            !(
              selectedAudienceType &&
              audienceTitle &&
              audienceAgency &&
              audienceBrand &&
              advertiser
            )
          }
          className="dropdownSaveButton primaryBtn"
          onClick={handleSave}
          loading={isMounted}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default AudienceWrap;
