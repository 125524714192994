import { LeftPanel } from '../LeftNavPanel';
import { AccountWrap } from './AccountManagementComponent';

const AccountManagement = () => {
  document.title = 'Accounts - BranchLab';

  return (
    <div>
      <LeftPanel defaultSelectedKeys={['8']} />
      <AccountWrap />
    </div>
  );
};

export default AccountManagement;
