/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import {
  CommonElementLabel,
  Multi,
  Single,
} from '../../../sharedComponent';
import {
  IDeleteParams,
  IMasterInsurancePlanProviderDDState,
  IMasterInsurancePlanTypeDDState,
} from '../../../../models/interfaces';
import {
  useMasterInsurancePlanProviderElementsStore,
  useMasterInsurancePlanTypeElementsStore,
} from '../../../../stores';

const InsuranceSelector: FC<{
  planType: number | null;
  provider: (number | string)[];
  setPlanType: React.Dispatch<React.SetStateAction<number | null>>;
  setProvider: React.Dispatch<React.SetStateAction<(number | string)[]>>;
  deleteSetParams?: IDeleteParams;
  targetingSetNumber?: number;
  featureSetNumber?: number;
  isDisabled?: boolean
}> = ({
  planType,
  provider,
  setPlanType,
  setProvider,
  deleteSetParams,
  targetingSetNumber,
  featureSetNumber,
  isDisabled
}) => {
  const [iPlanType, setIPlanType] = useState<number | null>(planType),
    [iProvider, setIProvider] = useState<(number | string)[]>(provider),
    [ifProviderUpdatedByPlan, setIfProviderUpdatedByPlan] =
      useState<boolean>(false),
    [ifFirstTime, setIfFirstTime] = useState<boolean>(true);

  const { insurancePlanProviderDDElements } =
    useMasterInsurancePlanProviderElementsStore(
      (state: IMasterInsurancePlanProviderDDState) => state
    );

  const { insurancePlanTypeDDElements } =
    useMasterInsurancePlanTypeElementsStore(
      (state: IMasterInsurancePlanTypeDDState) => state
    );

  useEffect(() => {
    if (!ifFirstTime) {
      setIfProviderUpdatedByPlan(true);
      setIProvider([]);
      setProvider([]);
    } else {
      setIfFirstTime(false);
    }
  }, [iPlanType]);

  return (
    <Row className="dualSelectorWrapper">
      <Col>
        <CommonElementLabel
          label="Payer Type"
          deleteSetParams={deleteSetParams}
        />
        <Single
          options={insurancePlanTypeDDElements}
          placeholder="Select Payer Type"
          componentSpecificClasses={['payerWrap']}
          isSearchable
          onChange={(data) => {
            setPlanType(data);
            setIPlanType(data);
          }}
          allowClear={false}
          isDisabled={isDisabled}
          targetingSetNumber={targetingSetNumber}
          featureSetNumber={featureSetNumber}
          props={{ value: planType }}
        />
      </Col>
      <Col>
        <CommonElementLabel label="Insurance Provider" />
        <Multi
          options={insurancePlanProviderDDElements}
          placeholder="Select Insurance Providers"
          isSearchable
          value={iProvider}
          onChange={(data) => {
            setProvider(data);
            setIProvider(data);
          }}
          isUpdateInDual={ifProviderUpdatedByPlan}
          setIsUpdateInDual={setIfProviderUpdatedByPlan}
          isDisabled={!iPlanType || iPlanType === -1 || isDisabled}
          targetingSetNumber={16}
          allowClear={false}
          featureSetNumber={featureSetNumber}
          props={{ defaultValue: provider }}
        />
      </Col>
    </Row>
  );
};

export default InsuranceSelector;
