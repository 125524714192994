import { FC } from 'react';

const LeftPanelMainMenu: FC<{}> = () => {
  return (
    <ul>
      <li>Audiences</li>
    </ul>
  );
};
export default LeftPanelMainMenu;
