import { LeftPanel } from '../LeftNavPanel';
import { InputField, PageHeader } from '../../sharedComponent';
import { useEffect, useState } from 'react';
import { useNotificationMessage, usePostApiInterceptor } from '../../../hooks';
import { RoutesEnum } from '../../../models/enums/apiRoutes';
import { IUserInformationState } from '../../../models/interfaces';
import { RbacSettings } from '../../../models/enums/rbacSetting';
import { useUserInformationStore } from '../../../stores';

type Taccountbody = {
  account_id: number;
  account_name: string;
  account_admin_email: string;
};

const AccountSettings = () => {
  document.title = 'Account Settings - BranchLab';
  const [isSettingMounted, setIsSettingMounted] = useState<boolean>(false),
    [body, setBody] = useState<any>({}),
    [settings, setSettings] = useState<any>(null),
    [isLoading, setIsLoading] = useState<boolean>(false),
    [accountName, setAccountName] = useState<string>(''),
    [accountBody, setAccountBody] = useState<Taccountbody | null>(null),
    [accountMounted, setAccountMounted] = useState<boolean>(false),
    [isActive, setIsActive] = useState<boolean>(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false });

  const { privilegeSetDDElements } = useUserInformationStore(
    (state: IUserInformationState) => state
  );
  const { context, destroyMessage } = useNotificationMessage(messageObj);

  const updateErrorMessage = (type: string, messageContent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messageContent,
      duration: 5,
    });
  };

  useEffect(() => {
    setBody({});
    setIsLoading(true);
    setIsSettingMounted(true);
  }, []);

  usePostApiInterceptor(
    accountMounted,
    accountBody,
    RoutesEnum.SAVE_ACCOUNT,
    (data: any, error: any) => {
      setIsLoading(false);
      setAccountBody(null);
      setAccountMounted(false);
      if (data && !error) {
        updateErrorMessage('success', 'Account is saved');
        setBody({});
        setIsSettingMounted(true);
      } else {
        updateErrorMessage('error', 'Error occurred while saving Account.');
      }
    }
  );

  usePostApiInterceptor(
    isSettingMounted,
    body,
    RoutesEnum.GET_ACCOUNT_SETTINGS,
    (data: any, error: any) => {
      setIsSettingMounted(false);
      if (error || !data) {
        setIsLoading(false);
        return;
      }
      setSettings(data);
      setAccountName(data.account_info[0].account_name);
      setIsSettingMounted(false);
      setIsLoading(false);
    }
  );

  const handleSave = () => {
    setAccountBody({
      account_id: settings.account_info[0].account_id,
      account_name: accountName,
      account_admin_email: settings.account_info[0].account_name,
    });
    destroyMessage('save');
    setMessageObj({ isShowing: false });
    setIsActive(false);
    setAccountMounted(true);
  };

  useEffect(() => {
    if (settings && settings.account_info[0].account_name === accountName) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }, [accountName, settings]);

  return (
    <div className="audienceSetupPage">
      <LeftPanel defaultSelectedKeys={['7', '71']} />
      {context}
      {!isLoading && (
        <>
          <div className="audienceCommonWrap">
            <PageHeader title="Account Settings" />
            <div className="accountSettingsNewField filterWrap">
              <InputField
                label="Account Name"
                props={{ value: accountName }}
                onChange={setAccountName}
              />
            </div>
          </div>
          {privilegeSetDDElements.includes(
            RbacSettings.ACCOUNT_SETTINGS_EDIT
          ) && (
            <div className="newFeatureSetBtn">
              <button
                disabled={!isActive || !accountName.length}
                className="primaryBtn"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AccountSettings;
