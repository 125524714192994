import React, { useEffect, useState } from 'react';
import { LeftPanel } from '../LeftNavPanel';
import { useParams } from 'react-router-dom';
import {
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useLoaderStore,
} from '../../../stores';
import {
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  ILoaderState,
} from '../../../models/interfaces';
import { usePostApiInterceptor } from '../../../hooks';
import { RoutesEnum } from '../../../models/enums/apiRoutes';
import { AudienceWrap } from './ViewAudienceComponents';

const ViewAudiencePage = () => {
  const { id } = useParams();
  

  document.title = 'View Audience - BranchLab';

  const [body, setBody] = useState<{ audience_id: string } | null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [isError, setIsError] = useState<boolean>(false);

  const {updateLoaderState, setUpdateLoaderState } = useLoaderStore(
      (state: ILoaderState) => state
    ),
    { setIfUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    { setUpdatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    );

  usePostApiInterceptor(
    isMounted,
    JSON.stringify(body),
    RoutesEnum.GET_FEATURE_SET_DATA,
    (data: any, error: any) => {
      setIsMounted(false);

      if (error || !data || (data && !data.audience_info)) {
        setIsError(true);
        setUpdateLoaderState(false);
        return;
      }
      setUpdatedDataSet(data.audience_info);
      setIfUpdated(true);
      setUpdateLoaderState(false);
    }
  );

  useEffect(() => {
    if (id) {
      setUpdatedDataSet({});
      isError && setIsError(false);
      setUpdateLoaderState(true);
      setBody({ audience_id: id });
      setIsMounted(true);
    }
  }, [id]);

  return (
    <div className="audienceSetupPage">
      <LeftPanel defaultSelectedKeys={['1', '2']} />
      {!updateLoaderState && (
        <AudienceWrap />
      )}
    </div>
  );
};

export default ViewAudiencePage;
