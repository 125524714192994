/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, Fragment, useEffect, useState } from 'react';
import { ModalComponent } from '../../../../components/sharedComponent';
import {
  useCheckDependentTargetingSelectedStore,
  useCheckIfDataModifiedStore,
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useMasterAdditionalOptionElementsStore,
  useMasterDmaElementsStore,
  useMasterOperatorElementsStore,
  useMasterStateElementsStore,
  useMasterTargetingElementsStore,
} from '../../../../stores';
import {
  IAudienceSaveMethods,
  IAudienceState,
  ICheckIfDataModifiedState,
  IDependentTargetingSelectedState,
  IEnableSaveState,
  IFeatureSetBaseData,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IFeatureSetElement,
  IFeatureSetParamDatum,
  IMasterAdditionalOptionDDState,
  IMasterDmaDDState,
  IMasterOperatorDDState,
  IMasterStateDDState,
  IMasterTargetingDDState,
} from '../../../../models/interfaces';
import { featureSetList } from './featureSetList';
import { Button, Dropdown, MenuProps, Select } from 'antd';
import { allSelectionOnlyElement } from '../../../../demoData/baseData';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import {
  useAudienceSaveMethods,
  useAudienceSets,
} from '../../../../stores/audienceSets.store';
import { dragDrop, more } from '../../../../assets/images';

type featureType =
  | 'genericDrug'
  | 'selectedDiagnosis'
  | 'selectedDrugs'
  | 'selectedProcedures'
  | 'selectedInsurancePlanType'
  | 'selectedInsuranceProviders'
  | 'selectedAdditionalOpts'
  | 'additionalInfo'
  | 'selectedProviderTaxonomy';

const baseValues: IFeatureSetBaseData = {
  genericDrug: [],
  selectedDiagnosis: [],
  selectedDrugs: [],
  selectedProcedures: [],
  selectedInsurancePlanType: null,
  selectedInsuranceProviders: [],
  selectedAdditionalOpts: [],
  additionalInfo: '',
};

const items: MenuProps['items'] = [
  {
    key: '1',
    label: 'Delete',
  },
];

const DefinedFeatureSet: FC<{
  featureSetNumber: number;
  featureSetOrderNumber: number;
  dragHandleProps?: DraggableProvidedDragHandleProps | null | undefined;
  disabled?: boolean;
  setDeletingSetId?: React.Dispatch<React.SetStateAction<number[] | null>>;
}> = ({
  featureSetNumber,
  featureSetOrderNumber,
  dragHandleProps,
  disabled = false,
  setDeletingSetId,
}) => {
  const {
      targetingDDElements,
      targetingDDElementsForFs,
      updateTargetingDDElementsForFs,
      setTargetingDDElementsForFs,
    } = useMasterTargetingElementsStore(
      (state: IMasterTargetingDDState) => state
    ),
    { updateIisFeatureSetsFilled } = useCheckSavingEnabilityStore(
      (state: IEnableSaveState) => state
    ),
    { stateDDElements } = useMasterStateElementsStore(
      (state: IMasterStateDDState) => state
    ),
    { additionalOptionDDElements } = useMasterAdditionalOptionElementsStore(
      (state: IMasterAdditionalOptionDDState) => state
    ),
    { operatorDDElements } = useMasterOperatorElementsStore(
      (state: IMasterOperatorDDState) => state
    ),
    { ifUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    { setIfDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    ),
    { isEnableSaveForEachSet, updateIsEnableSaveForEachSet } =
      useCheckSavingEnabilityStore((state: IEnableSaveState) => state),
    { updateSavingDataSet, updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { updateDmaDDElements } = useMasterDmaElementsStore(
      (state: IMasterDmaDDState) => state
    ),
    { updateIsDependentTargetingSelectedForEachSet } =
      useCheckDependentTargetingSelectedStore(
        (state: IDependentTargetingSelectedState) => state
      ),
    { isSaveInitiated, setIsSaveInitiated } = useAudienceSaveMethods(
      (state: IAudienceSaveMethods) => state
    ),
    {
      setDeletedIds,
      deletedIds,
      setIsCancelled,
      isCancelled,
      firstFeatureNumber,
      defineFeatureSet,
      setDefineFeatureSet,
      updateDefineFeatureTargeting,
      updateDefineFeatureValues,
      updateDefineOperator,
    } = useAudienceSets((state: IAudienceState) => state);

  const [featureSetBody, setFeatureSetBody] = useState<IFeatureSetElement[]>(
      []
    ),
    [ifUpdatedCompleted, setIfUpdatedCompleted] = useState<0 | 1 | 2>(0),
    [modalToOpen, setModalToOpen] = useState(false);

  useEffect(() => {
    if (!defineFeatureSet[featureSetNumber]) {
      defineFeatureSet[featureSetNumber] = {
        targeting: [],
        featureSetValues: JSON.parse(JSON.stringify(baseValues)),
        operator: 5,
      };
      setDefineFeatureSet(defineFeatureSet);
    }
  }, []);

  useEffect(() => {
    if (
      targetingDDElementsForFs.length === 0 &&
      Object.keys(updatedDataSet).length <= 0
    ) {
      let newOptions = targetingDDElements.filter(
        (tar) => !tar.is_hpa && !tar.is_caregiver && !tar.is_global
      );
      setTargetingDDElementsForFs(newOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newTargeting = defineFeatureSet[featureSetNumber]?.targeting || [];
  const newFeatureValues =
    defineFeatureSet[featureSetNumber]?.featureSetValues ||
    JSON.parse(JSON.stringify(baseValues));
  const newFeatureOperator = defineFeatureSet[featureSetNumber]?.operator ?? 5;

  useEffect(() => {
    if (isCancelled) {
      setFeatureSetBody([]);
      setIsCancelled(false);
    }
  }, [isCancelled]);

  const handleTargetableOptions = (value: any) => {
    updateDefineFeatureTargeting(value, featureSetNumber);
    let newOptions = targetingDDElementsForFs.filter(
      (tar) =>
        tar.value !== value &&
        !tar.is_hpa &&
        !tar.is_caregiver &&
        !tar.is_global
    );
    setTargetingDDElementsForFs(newOptions);
  };

  const handleOperator = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement;
    const id = target.id;
    updateDefineOperator(parseInt(id), featureSetNumber);
  };

  const checkSaveEnabled = () => {
    switch (true) {
      case !newTargeting?.length:
        return 0;
      case newTargeting.includes(9) &&
        !newFeatureValues.selectedDiagnosis?.length:
      case newTargeting.includes(10) && !newFeatureValues.selectedDrugs?.length:
      case newTargeting.includes(11) &&
        !newFeatureValues.selectedProcedures?.length:
      case newTargeting.includes(18) && !newFeatureValues.genericDrug.length:
      case newTargeting.includes(12) &&
        (!newFeatureValues.selectedInsurancePlanType ||
          (newFeatureValues.selectedInsurancePlanType &&
            newFeatureValues.selectedInsurancePlanType !== -1 &&
            !newFeatureValues.selectedInsuranceProviders?.length)):
        return 1;
      default:
        return 2;
    }
  };

  useEffect(() => {
    updateIsEnableSaveForEachSet(featureSetNumber, checkSaveEnabled());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTargeting, newFeatureValues]);

  const handleFeatureSetUpdates = (key: string, data: any) => {
    if (data) {
      if (Array.isArray(data)) {
        updateDefineFeatureValues(data, key, true, featureSetNumber);
      } else {
        updateDefineFeatureValues(data, key, false, featureSetNumber);
      }
    }
  };

  useEffect(() => {
    updateDmaDDElements(featureSetNumber, [], stateDDElements);
  }, []);

  useEffect(() => {
    performValidations();
  }, []);

  const validationInsuranceProvider = () => {
    if (
      newTargeting.includes(12) &&
      newFeatureValues.selectedInsurancePlanType &&
      newFeatureValues.selectedInsurancePlanType !== -1 &&
      newFeatureValues.selectedInsuranceProviders
    ) {
      updateIisFeatureSetsFilled(
        featureSetNumber,
        16,
        !newFeatureValues.selectedInsuranceProviders?.length ? 1 : 2
      );
      if (!newFeatureValues.selectedInsuranceProviders?.length) {
        setIsSaveInitiated(false);
      }
      return;
    }

    updateIisFeatureSetsFilled(featureSetNumber, 16, 0);
  };

  const performValidations = () => {
    validationForArray(9, newFeatureValues.selectedDiagnosis);
    validationForArray(10, newFeatureValues.selectedDrugs);
    validationForArray(11, newFeatureValues.selectedProcedures);
    validationForNonArray(12, newFeatureValues.selectedInsurancePlanType);
    validationInsuranceProvider();
    validationForArray(18, newFeatureValues.genericDrug);
  };

  const validationForNonArray = (tId: number, data: any) => {
    if (newTargeting.includes(tId)) {
      updateIisFeatureSetsFilled(featureSetNumber, tId, !data ? 1 : 2);
      if (!data) {
        setIsSaveInitiated(false);
      }
      return;
    }
    updateIisFeatureSetsFilled(featureSetNumber, tId, 0);
  };

  const validationForArray = (tId: number, data: any) => {
    if (newTargeting.includes(tId)) {
      updateIisFeatureSetsFilled(featureSetNumber, tId, !data?.length ? 1 : 2);
      if (!data.length) {
        setIsSaveInitiated(false);
      }
      return;
    }
    updateIisFeatureSetsFilled(featureSetNumber, tId, 0);
  };

  const handleSetDelete = (setIds: [number, keyof IFeatureSetBaseData]) => {
    const newTargetingElemArray = newTargeting.filter(
      (el) => !(setIds[0] === (el as number))
    );
    let ids: featureType = setIds[1];
    if (newTargeting.find((el) => setIds[0] === (el as number))) {
      setDeletedIds([setIds[0]]);
    }
    updateTargetingDDElementsForFs(setIds[0]);
    defineFeatureSet[featureSetNumber].targeting = newTargetingElemArray;
    defineFeatureSet[featureSetNumber].featureSetValues[setIds[1]] =
      baseValues[ids];
    setDefineFeatureSet(defineFeatureSet);
  };

  const getParams = () => {
    const params: IFeatureSetParamDatum[] = [
      {
        value: 9,
        values: [newFeatureValues.selectedDiagnosis],
        names: ['selectedDiagnosis'],
        base: [baseValues.selectedDiagnosis],
        param: {
          setId: 9,
          featureSetNumber,
          label: 'Diagnosis',
          isMandatory: false,
          placeholder: 'Select Diagnosis',
          esParams: JSON.parse(
            process.env?.REACT_APP_ES_DIAGNOSIS_PARAMS ?? '{}'
          ),
          value: newFeatureValues.selectedDiagnosis,
          onChange: (data: any) => {
            setIfDataModified(true);
            handleFeatureSetUpdates('selectedDiagnosis', data);
          },
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 10,
        values: [newFeatureValues.selectedDrugs],
        names: ['selectedDrugs'],
        base: [baseValues.selectedDrugs],
        param: {
          setId: 10,
          featureSetNumber,
          label: 'Drug',
          isMandatory: false,
          placeholder: 'Select Drugs',
          esParams: JSON.parse(process.env?.REACT_APP_ES_DRUG_PARAMS ?? '{}'),
          value: newFeatureValues.selectedDrugs,
          onChange: (data: any) => {
            setIfDataModified(true);
            handleFeatureSetUpdates('selectedDrugs', data);
          },
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 11,
        values: [newFeatureValues.selectedProcedures],
        names: ['selectedProcedures'],
        base: [baseValues.selectedProcedures],
        param: {
          setId: 11,
          featureSetNumber,
          label: 'Procedure',
          isMandatory: false,
          options: allSelectionOnlyElement,
          placeholder: 'Select Procedures',
          esParams: JSON.parse(
            process.env?.REACT_APP_ES_PROCEDURE_PARAMS ?? '{}'
          ),
          value: newFeatureValues.selectedProcedures,
          onChange: (data: any) => {
            handleFeatureSetUpdates('selectedProcedures', data);
            setIfDataModified(true);
          },
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 12,
        values: [
          newFeatureValues.selectedInsurancePlanType,
          newFeatureValues.selectedInsuranceProviders,
        ],
        names: ['selectedInsurancePlanType', 'selectedInsuranceProviders'],
        base: [
          baseValues.selectedInsurancePlanType,
          baseValues.selectedInsuranceProviders,
        ],
        param: {
          setId: 12,
          featureSetNumber,
          selectedPlanType: newFeatureValues.selectedInsurancePlanType,
          selectedProviders: newFeatureValues.selectedInsuranceProviders,
          setSelectedPlanType: (data: any) => {
            setIfDataModified(true);
            handleFeatureSetUpdates('selectedInsurancePlanType', data);
          },
          setSelectedProviders: (data: any) => {
            setIfDataModified(true);
            handleFeatureSetUpdates('selectedInsuranceProviders', data);
          },
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 13,
        values: [newFeatureValues.selectedAdditionalOpts],
        names: ['selectedAdditionalOpts'],
        base: [baseValues.selectedAdditionalOpts],
        param: {
          setId: 13,
          featureSetNumber,
          label: 'Additional Options',
          isMandatory: false,
          options: additionalOptionDDElements,
          placeholder: 'Select Additional Options',
          isSearchable: true,
          onChange: (data: any) => {
            setIfDataModified(true);
            handleFeatureSetUpdates('selectedAdditionalOpts', data);
          },
          handleSetDelete,
          otherProperties: {
            defaultValue: newFeatureValues.selectedAdditionalOpts,
          },
          isDisabled: disabled,
        },
      },
      {
        value: 14,
        values: [newFeatureValues.additionalInfo],
        names: ['additionalInfo'],
        base: [baseValues.additionalInfo],
        param: {
          setId: 14,
          featureSetNumber,
          label: 'Additional Information',
          fieldType: 'textarea',
          inputPlaceholder: 'Additional Information',
          additionalInfo: newFeatureValues.additionalInfo,
          onChange: (data: any) => {
            setIfDataModified(true);
            handleFeatureSetUpdates('additionalInfo', data);
          },
          handleSetDelete,
          props: { autoSize: { minRows: 1, maxRows: 1 } },
          isDisabled: disabled,
        },
      },
      {
        value: 18,
        values: [newFeatureValues.genericDrug],
        names: ['genericDrug'],
        base: [baseValues.genericDrug],
        param: {
          setId: 18,
          featureSetNumber,
          label: 'Generic Drug',
          isMandatory: false,
          placeholder: 'Select Drugs',
          esParams: JSON.parse(
            process.env?.REACT_APP_ES_DRUG_GENERIC_PARAMS ?? '{}'
          ),
          isSearchable: true,
          value: newFeatureValues.genericDrug,
          onChange: (data: any) => {
            setIfDataModified(true);
            handleFeatureSetUpdates('genericDrug', data);
          },
          handleSetDelete,
          isDisabled: disabled,
        },
      },
    ];
    return params;
  };

  useEffect(() => {
    if (newTargeting?.length) {
      const updatedElem: IFeatureSetElement[] = [],
        params = getParams();

      newTargeting.forEach((el: number | string, index: number) => {
        updatedElem.push({
          setId: el as number,
          orderId: index,
          element: featureSetList
            .find((elem) => elem.value === (el as number))
            ?.component(
              params.find((elem) => elem.value === (el as number))?.param
            ),
        });
      });

      setFeatureSetBody(updatedElem);
    }
  }, [newTargeting, newFeatureValues]);

  const getFormattedData = () => {
    const tempData: any = {
        ...newFeatureValues,
      },
      allUpdatingFeatureSetIds = updatedDataSet?.feature_sets?.map(
        (el: any) => el.feature_set_id
      );
    let allUpdatingTargetingIds: any = {};
    updatedDataSet?.feature_sets?.forEach((el: any) => {
      if (el.feature_set_id === featureSetNumber) {
        el.targeting.forEach(
          (elem: any) =>
            (allUpdatingTargetingIds = {
              ...allUpdatingTargetingIds,
              [elem.targeting_id]: elem.targeting_set_id,
            })
        );
      }
    });

    let newFeatureSetData: any = {
        feature_set_id: allUpdatingFeatureSetIds?.includes(featureSetNumber)
          ? featureSetNumber
          : 0,
        feature_set_rank: featureSetOrderNumber,
        condition_id:
          featureSetOrderNumber !== firstFeatureNumber
            ? defineFeatureSet[featureSetNumber].operator
            : 0,
        is_global: false,
        is_caregiver: false,
      },
      targeting = [],
      duals: any = {
        selectedInsuranceProviders: 16,
      };
    for (const key in newFeatureValues) {
      if (
        !(
          !tempData[key] ||
          (Array.isArray(tempData[key]) && tempData[key].length < 1)
        )
      ) {
        const params = getParams(),
          targetingId = params.find((el: IFeatureSetParamDatum) =>
            el.names.includes(key as any)
          )?.value,
          usableTargetingId = Object.keys(duals).includes(key)
            ? duals[key]
            : targetingId,
          usableTargetingSetId = Object.keys(allUpdatingTargetingIds).includes(
            `${usableTargetingId}`
          )
            ? allUpdatingTargetingIds[`${usableTargetingId}`]
            : 0;

        targeting.push({
          targeting_set_id: usableTargetingSetId,
          targeting_id: usableTargetingId,
          targeting_value: tempData[key],
          targeting_set_rank: newTargeting.indexOf(targetingId as number) + 1,
        });
      }
    }

    if (isSaveInitiated && Object.keys(newFeatureSetData)?.length) {
      updateSavingDataSet(featureSetNumber, {
        ...newFeatureSetData,
        targeting,
      });
    }
  };

  useEffect(() => {
    if (isSaveInitiated) {
      const isEnableValues = Object.values(isEnableSaveForEachSet);
      performValidations();
      if (isEnableValues.includes(1)) {
        setIsSaveInitiated(false);
        return;
      }
      if (newTargeting?.length) {
        getFormattedData();
      } else {
        updateSavingDataSet(featureSetNumber, {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveInitiated]);

  useEffect(() => {
    if (deletedIds?.length) {
      setDeletedIds(null);
      const params = getParams(),
        selectedParam: IFeatureSetParamDatum[] = params.filter((el: any) =>
          deletedIds?.includes(el.value)
        ),
        // tempFeatureSetData = { ...featureSetData },
        dependentTargets = [6, 7, 8];

      selectedParam?.length &&
        selectedParam.forEach((eachParam: IFeatureSetParamDatum) => {
          if (dependentTargets.includes(eachParam.value)) {
            updateIsDependentTargetingSelectedForEachSet(
              featureSetNumber,
              false
            );
          }
        });
    }

    if (newTargeting?.length) {
      const params = getParams(),
        updatedElem: IFeatureSetElement[] = [],
        dependentTargets = [6, 7, 8];

      updateIsDependentTargetingSelectedForEachSet(
        featureSetNumber,
        Boolean(
          newTargeting.find((el) => dependentTargets.includes(el as number))
        )
      );

      newTargeting.forEach((el: number | string, index: number) => {
        updatedElem.push({
          setId: el as number,
          orderId: index,
          element: featureSetList
            .find((elem) => elem.value === (el as number))
            ?.component(
              params.find((elem) => elem.value === (el as number))?.param
            ),
        });
      });
      setFeatureSetBody(updatedElem);
    } else {
      setFeatureSetBody([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTargeting]);

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === '1') {
      setModalToOpen(true);
    }
  };

  useEffect(() => {
    const allUpdatingFeatureSetIds = updatedDataSet?.feature_sets?.map(
      (el: any) => el.feature_set_id
    );

    if (
      ifUpdated &&
      !ifUpdatedCompleted &&
      updatedDataSet?.feature_sets?.length &&
      allUpdatingFeatureSetIds?.includes(featureSetNumber)
    ) {
      setIfUpdatedCompleted(1);
      const featureSet = updatedDataSet.feature_sets.find(
        (el: any) =>
          el.feature_set_id === featureSetNumber &&
          !el.is_caregiver &&
          !el.is_hcp &&
          !el.is_global
      );

      if (featureSet) {
        const params = getParams(),
          duals: any = { 17: 1, 16: 12 },
          targetId: any = [],
          targetingSet = featureSet.targeting.sort(
            (a: any, b: any) => a.targeting_id - b.targeting_id
          );

        let newTargetingSet: any = [];
        // let tempFeatureSetDataForMessage = {};

        targetingSet.forEach((el: any) => {
          targetId.push(el.targeting_id);
          const keys = Object.keys(duals);

          if (keys.includes(el.targeting_id.toString())) {
            const index: number = newTargetingSet.findIndex(
              (elem: any) => elem.targeting_id === duals[`${el.targeting_id}`]
            );

            newTargetingSet[index] = {
              ...newTargetingSet[index],
              targeting_value: [
                ...newTargetingSet[index].targeting_value,
                JSON.parse(el.targeting_value),
              ],
              uniqueIds: [...newTargetingSet[index].uniqueIds, el.targeting_id],
            };
          } else {
            newTargetingSet.push({
              ...el,
              targeting_value:
                el.targeting_id !== 14
                  ? [JSON.parse(el.targeting_value)]
                  : el.targeting_value,
              uniqueIds: [el.targeting_id],
            });
          }
        });

        const usableTargetingSet = newTargetingSet.sort(
          (a: any, b: any) => a.targeting_set_rank - b.targeting_set_rank
        );

        usableTargetingSet.forEach((el: any) => {
          const param = params.find(
            (param: IFeatureSetParamDatum) => param.value === el.targeting_id
          );
          if (param) {
            if (el.uniqueIds[0] === 12) {
              defineFeatureSet[featureSetNumber].featureSetValues[
                'selectedInsurancePlanType'
              ] = el.targeting_value[0];
              defineFeatureSet[featureSetNumber].featureSetValues[
                'selectedInsuranceProviders'
              ] = el.targeting_value[1];
            }
            if (
              param.names[0] === 'genericDrug' ||
              param.names[0] === 'selectedDiagnosis' ||
              param.names[0] === 'selectedProcedures' ||
              param.names[0] === 'selectedAdditionalOpts' ||
              param.names[0] === 'additionalInfo' ||
              param.names[0] === 'selectedDrugs'
            ) {
              defineFeatureSet[featureSetNumber].featureSetValues[
                param.names[0]
              ] = el.targeting_value[0];
            }
          }
        });
        const operatorIds = operatorDDElements.map((el) => el.value);
        const finalOperator = operatorIds.includes(featureSet.condition_id)
          ? featureSet.condition_id
          : operatorIds[0];
        defineFeatureSet[featureSetNumber].operator = finalOperator;
        defineFeatureSet[featureSetNumber].targeting = usableTargetingSet.map(
          (el: any) => el.targeting_id
        );
        setDefineFeatureSet(defineFeatureSet);
      }
    }

    if (!ifUpdated) {
      setIfUpdatedCompleted(0);
    }
  }, []);

  return (
    <Fragment>
      {modalToOpen && (
        <ModalComponent
          modalHeader="Delete FeatureSet"
          modalToOpen={modalToOpen}
          setModalToOpen={setModalToOpen}
          deleteSetParams={{
            setId: featureSetNumber,
            name: `FeatureSet ${featureSetOrderNumber}`,
            targetingArr: newTargeting,
            handleDelete: setDeletingSetId,
          }}
        />
      )}
      {featureSetOrderNumber > 1 && (
        <div className="featureSetHandleWrap">
          <button
            className={`noStyleDropdown ${
              newFeatureOperator.toString() === '5'
                ? 'activeFeatureSetHandleOption'
                : ''
            }`}
            disabled={disabled}
            id="5"
            onClick={handleOperator}
          >
            And
          </button>
          <button
            className={`noStyleDropdown ${
              newFeatureOperator.toString() === '6'
                ? 'activeFeatureSetHandleOption'
                : ''
            }`}
            disabled={disabled}
            id="6"
            onClick={handleOperator}
          >
            Or
          </button>
          <button
            className={`noStyleDropdown ${
              newFeatureOperator.toString() === '8'
                ? 'activeFeatureSetHandleOption'
                : ''
            }`}
            disabled={disabled}
            id="8"
            onClick={handleOperator}
          >
            And Not
          </button>
        </div>
      )}
      <div className="featureSetWrapElement">
        <div className="featureSetHeadingWrap">
          <div>
            {!disabled && <img src={dragDrop} {...dragHandleProps} alt="" />}
            FeatureSet {featureSetOrderNumber}
          </div>

          {!disabled && (
            <Dropdown
              menu={{ items, onClick }}
              placement="bottomLeft"
              className="noStyleDropdown"
              trigger={['click']}
            >
              <Button>
                <img src={more} alt="" />
              </Button>
            </Dropdown>
          )}
        </div>
        <div>
          {newTargeting.length > 0 && (
            <div>
              {featureSetBody.map((el: IFeatureSetElement) => (
                <Fragment key={`${el.orderId}_${el.setId}`}>
                  {el.element}
                </Fragment>
              ))}
            </div>
          )}
          {!disabled && newTargeting.length < 1 && (
            <Select
              className="customAntSelect"
              options={targetingDDElementsForFs}
              placeholder="Select Targetable Feature"
              onChange={(data: number) => handleTargetableOptions(data)}
              value={null}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default DefinedFeatureSet;
