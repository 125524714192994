/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  ModalComponent,
  PageHeader,
} from '../../../../components/sharedComponent';
import DefaultFeatureSet from './DefaultFeatureSet';
import {
  useCheckIfDataModifiedStore,
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useLoaderStore,
  useMasterAudienceTypeElementsStore,
} from '../../../../stores';
import {
  IAudienceSaveMethods,
  IAudienceSetUpState,
  IAudienceState,
  ICheckIfDataModifiedState,
  IEnableSaveState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  ILoaderState,
  IMasterAdvertiserDDState,
  IMasterAgencyDDState,
  IMasterAudienceTypeDDState,
  IMasterBrandDDState,
} from '../../../../models/interfaces';
import { Button, Dropdown, MenuProps } from 'antd';
import TargetableFeatureSetup from './TargetableFeatureSetup';
import {
  useAudienceSaveMethods,
  useAudienceSetUp,
  useAudienceSets,
} from '../../../../stores/audienceSets.store';
import { CheckOutlined } from '@ant-design/icons';
import {
  useRedirection,
  useNotificationMessage,
  usePostApiInterceptor,
} from '../../../../hooks';
import { useParams } from 'react-router-dom';
// import AudienceInsightsWrap from './AudienceInsights/AudienceInsightsWrap';
import { barChart, chevDown } from '../../../../assets/images';
import {
  useMasterAdvertiserElmentStore,
  useMasterAgencyElmentStore,
  useMasterBrandElmentStore,
} from '../../../../stores/masterData.store';
import { OrganisationModal } from '../../AccountSettings/AccountSettingsComponent';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';

type SavingOptionType = {
  key: '1' | '2' | '3' | '4';
  label:
    | 'Save and Exit'
    | 'Save and Activate'
    | 'Save and New'
    | 'Save and Duplicate';
  messagecontent: string;
};
export const savingOptions: SavingOptionType[] = [
  {
    key: '1',
    label: 'Save and Exit',
    messagecontent: 'Audience is saved',
  },
  {
    key: '2',
    label: 'Save and New',
    messagecontent: 'Audience is saved',
  },
];

type TorganisationBody = {
  organization_type: string | null;
  id: number;
  name: string;
};

const AudienceSetupFieldsWrap = () => {
  const { id } = useParams();
  const [selectedAudienceType, setSelectedAudienceType] = useState<
      number | null
    >(null),
    [modalToOpenForCancel, setModalToOpenForCancel] = useState<boolean>(false),
    [selectedAdditionalOpts, setSelectedAdditionalOpts] =
      useState<boolean>(false),
    [focusOnTitle, setFocusOnTitle] = useState<0 | 1 | 2>(0),
    [organisationType, setOrganisationType] = useState<string | null>(null),
    [openOrganisation, setOpenOrganisation] = useState<boolean>(false),
    [fieldVal, setFieldVal] = useState<string>(''),
    [orgainsationMounted, setOrgainsationMounted] = useState<boolean>(false),
    [orgainsationBody, setOrganisationBody] =
      useState<TorganisationBody | null>(null),
    [organisationId, setOrganisationId] = useState<number>(0),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false });

  const { audienceTypeDDElements } = useMasterAudienceTypeElementsStore(
      (state: IMasterAudienceTypeDDState) => state
    ),
    { agencyDDElements, updateAgencyDDElements } = useMasterAgencyElmentStore(
      (state: IMasterAgencyDDState) => state
    ),
    { brandDDElements, updateBrandDDElements } = useMasterBrandElmentStore(
      (state: IMasterBrandDDState) => state
    ),
    { advertiserDDElements, updateAdvertiserDDElements } = useMasterAdvertiserElmentStore(
      (state: IMasterAdvertiserDDState) => state
    ),
    { ifDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    ),
    { showBlankLoaderState, loaderState } = useLoaderStore(
      (state: ILoaderState) => state
    ),
    {
      advertiser,
      setAdvertiser,
      audiencePageNumber,
      setAudiencePageNumber,
      audienceTitle,
      audienceBrand,
      audienceAgency,
      setAudienceAgency,
      setAudiencebrand,
      setAudienceTitle,
    } = useAudienceSetUp((state: IAudienceSetUpState) => state),
    { setIsSaveInitiated } = useAudienceSaveMethods(
      (state: IAudienceSaveMethods) => state
    ),
    {
      isClone,
      setIsClone,
      isDataSaved,
      selectedSavingOpt,
      setSelectedSavingOpt,
      setDefineFeatureData,
      setAudienceSummary,
      setIsCollapsed,
      isCollapsed,
    } = useAudienceSets((state: IAudienceState) => state),
    { updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { ifUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    { setIisFeatureSetsFilled } = useCheckSavingEnabilityStore(
      (state: IEnableSaveState) => state
    );
  const { redirectAudienceListing } = useRedirection();

  const { context, destroyMessage } = useNotificationMessage(messageObj);

  const updateErrorMessage = (type: string, messageContent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messageContent,
      duration: 5,
    });
  };

  useEffect(() => {
    return () => {
      setAudienceTitle('');
      setSelectedAudienceType(null);
      setIisFeatureSetsFilled({});
      setAudienceAgency(null);
      setAudiencebrand(null);
      setAdvertiser(null);
      setDefineFeatureData([]);
    };
  }, []);

  useEffect(() => {
    setAudienceSummary('');
    setAudiencePageNumber(1);
  }, []);

  const handleCancel = () => {
    if (ifDataModified) {
      setModalToOpenForCancel(true);
    } else {
      setAudienceTitle('');
      setAudienceAgency(null);
      setAudiencebrand(null);
      setAdvertiser(null)
      redirectAudienceListing();
      setSelectedAudienceType(null);
      setDefineFeatureData([]);
    }
  };

  const onSavingMenuClick: MenuProps['onClick'] = (e) => {
    destroyMessage('save');
    setMessageObj({ isShowing: false });
    setSelectedSavingOpt(e.key);
    setIsSaveInitiated(true);
  };

  const getSaveLabel = () => {
    if (isDataSaved) {
      return (
        <>
          <CheckOutlined /> Saved
        </>
      );
    }
    if (showBlankLoaderState) {
      return <>Saving</>;
    }
    return <>Continue</>;
  };

  useEffect(()=>{
    if(selectedAudienceType === 2){
      setSelectedAdditionalOpts(false)
    }
  },[selectedAudienceType])

  const handleBack = () => {
    setAudiencePageNumber(audiencePageNumber - 1);
  };

  usePostApiInterceptor(
    orgainsationMounted,
    orgainsationBody,
    RoutesEnum.SAVE_ACCCOUNT_ORGANISATION,
    (data: any, error: any) => {
      if (data && !error) {
        updateErrorMessage(
          'success',
          `${
            organisationType === 'advertiser'
              ? 'Advertiser'
              : organisationType === 'agency'
              ? 'Agency'
              : 'Brand'
          } is saved`
        );
        if(organisationType === 'advertiser'){
          updateAdvertiserDDElements(orgainsationBody?.name ?? '', data.org_id)
          setAdvertiser(data.org_id)
        }else if(organisationType === 'agency'){
          updateAgencyDDElements(orgainsationBody?.name ?? '', data.org_id)
          setAudienceAgency(data.org_id)
        }else{
          updateBrandDDElements(orgainsationBody?.name ?? '', data.org_id)
          setAudiencebrand(data.org_id)
        }
      } else {
        updateErrorMessage(
          'error',
          data.msg
            ? `${data.msg}`
            : `Error occurred while saving ${
                organisationType === 'advertiser'
                  ? 'Advertiser'
                  : organisationType === 'agency'
                  ? 'Agency'
                  : 'Brand'
              }.`
        );
      }
      setOrganisationBody(null);
      setOrgainsationMounted(false);
    }
  );

  useEffect(() => {
    if (ifUpdated) {
      const {
        title,
        audience_type_id,
        additional_option_id,
        advertiser_id: advertiser,
        agency_id: audience_agency,
        brand_id: audience_brand,
        feature_sets,
      } = updatedDataSet;
      setAudienceSummary(
        feature_sets.length ? feature_sets[0].feature_set_summary : ''
      );
      setAdvertiser(advertiser);
      setAudienceAgency(audience_agency);
      setAudiencebrand(audience_brand);
      setAudienceTitle(title);
      setSelectedAudienceType(audience_type_id);
      setSelectedAdditionalOpts(additional_option_id);
    }
  }, [ifUpdated]);

  const handleModalCancel = () => {
    setAudienceTitle('');
    setAudienceAgency(null);
    setAudiencebrand(null);
    setAdvertiser(null);
    redirectAudienceListing();
    setSelectedAudienceType(null);
    setDefineFeatureData(null);
    setAudiencePageNumber(1);
  };

  const handleContinue = () => {
    setAudiencePageNumber(audiencePageNumber + 1);
  };

  const handleSaveOrganisation = (value: string) => {
    setOrganisationBody({
      organization_type: organisationType,
      id: organisationId,
      name: value,
    });
    setOrgainsationMounted(true);
  };

  return (
    <>
      <div className="audienceCommonWrap">
        {context}
        <PageHeader
          title={
            id ? (
              <>
                Update: <span className="lessOpacityText">{audienceTitle}</span>
              </>
            ) : (
              'Create Audience'
            )
          }
        />
        {!loaderState && (
          <>
            {audiencePageNumber === 1 && (
              <div>
                <DefaultFeatureSet
                  defaultTitle={audienceTitle}
                  setTitle={setAudienceTitle}
                  audienceType={selectedAudienceType}
                  setAudienceType={(value: any) =>
                    setSelectedAudienceType(value)
                  }
                  advertiser={advertiser}
                  setAdvertiser={setAdvertiser}
                  defaultAgency={audienceAgency}
                  setAgency={setAudienceAgency}
                  defaultBrand={audienceBrand}
                  agencyDDElements={agencyDDElements}
                  brandDDElements={brandDDElements}
                  advertiserDDElements={advertiserDDElements}
                  setBrand={setAudiencebrand}
                  selectedAdditionalOpts={selectedAdditionalOpts}
                  setSelectedAdditionalOpts={setSelectedAdditionalOpts}
                  audienceTypeDDElements={audienceTypeDDElements}
                  focusOnTitle={focusOnTitle}
                  setOrganisationType={setOrganisationType}
                  openOrganisation={setOpenOrganisation}
                />
              </div>
            )}
            {audiencePageNumber === 2 && (
              <div className="activationRightPanel">
                <div className="targetableFeatureAndAudienceInsight">
                  <TargetableFeatureSetup
                    setIsClone={setIsClone}
                    setMessageObj={setMessageObj}
                    setFocusOnTitle={setFocusOnTitle}
                    selectedAudienceType={selectedAudienceType}
                    selectedAdditionalOpts={selectedAdditionalOpts}
                    setSelectedAudienceType={setSelectedAudienceType}
                    setModalToOpenForCancel={setModalToOpenForCancel}
                    setSelectedAdditionalOpts={setSelectedAdditionalOpts}
                  />
                </div>
                {/* <AudienceInsightsWrap /> */}
              </div>
            )}
          </>
        )}
        <OrganisationModal
          type={
            organisationType === 'agency'
              ? 'agency'
              : organisationType === 'brand'
              ? 'brand'
              : 'advertiser'
          }
          isOpen={openOrganisation}
          onClose={() => setOpenOrganisation(false)}
          onSave={handleSaveOrganisation}
          value={fieldVal}
          setVal={setFieldVal}
          setId={setOrganisationId}
        />
      </div>
      <div className="newFeatureSetBtn">
        {audiencePageNumber !== 1 && (
          <button
            className="responsiveCTAForRightPanel"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <img src={barChart} alt="" />
          </button>
        )}

        <div className="editAudienceNewFeatureSetBtn">
          <ModalComponent
            modalHeader={
              'Cancel ' + (id && !isClone ? 'Update' : 'Create') + ' Audience'
            }
            modalToOpen={modalToOpenForCancel}
            setModalToOpen={setModalToOpenForCancel}
            deleteSetParams={{
              name:
                'Cancel ' +
                (id && !isClone ? 'Update' : 'Create') +
                ' Audience',
              handelOk: handleModalCancel,
              message:
                'You’re about to lose any unsaved changes. Do you want to continue?',
              okText: 'Yes',
              cancelText: 'No',
            }}
          />
          <Button
            type="text"
            size="large"
            onClick={handleCancel}
            className="noBGBtn"
          >
            Cancel
          </Button>
          {audiencePageNumber === 2 && (
            <Button
              size="large"
              onClick={handleBack}
              className="secondaryBtn backBtn"
            >
              Back
            </Button>
          )}
          {audiencePageNumber === 1 && (
            <Button
              type="primary"
              size="large"
              className="dropdownSaveButton primaryBtn"
              onClick={handleContinue}
              disabled={
                !(
                  selectedAudienceType &&
                  audienceTitle &&
                  audienceAgency &&
                  audienceBrand &&
                  advertiser
                )
              }
            >
              Continue
            </Button>
          )}
          {audiencePageNumber === 2 && (
            <Dropdown.Button
              trigger={['click']}
              type="primary"
              size="large"
              className="dropdownSaveButton"
              icon={<img src={chevDown} alt="arrow" />}
              loading={!isDataSaved && showBlankLoaderState}
              onClick={() => {
                setIsSaveInitiated(true);
                setSelectedSavingOpt('');
              }}
              disabled={
                !(
                  selectedAudienceType &&
                  audienceTitle &&
                  audienceAgency &&
                  audienceBrand &&
                  advertiser
                )
              }
              menu={{
                selectedKeys: [selectedSavingOpt],
                items: savingOptions,
                onClick: onSavingMenuClick,
              }}
            >
              {getSaveLabel()}
            </Dropdown.Button>
          )}
        </div>
      </div>
    </>
  );
};

export default AudienceSetupFieldsWrap;
