import { FC } from 'react';
import { DeleteTargetingElement } from '../';
import { IDeleteParams } from '../../../models/interfaces';

const CommonElementLabel: FC<{
  label?: string;
  isMandatory?: boolean;
  deleteSetParams?: IDeleteParams;
  specificWrapperClass?: string;
}> = ({
  label,
  isMandatory = false,
  deleteSetParams,
  specificWrapperClass,
}) => {
  return label ? (
    <div
      className={`elementLabel${
        specificWrapperClass ? ' ' + specificWrapperClass : ''
      }`}
    >
      <div>
        {label}
        {isMandatory && <span className="isMandatory">*</span>}
      </div>
      {deleteSetParams && deleteSetParams.showRemove && (
        <DeleteTargetingElement deleteSetParams={deleteSetParams} />
      )}
    </div>
  ) : (
    <></>
  );
};

export default CommonElementLabel;
