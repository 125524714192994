/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState, Fragment } from 'react';
import DefinedFeatureSet from './DefinedFeatureSet';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  useCheckDependentTargetingSelectedStore,
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useLoaderStore,
  useMasterTargetingElementsStore,
  useUserInformationStore,
} from '../../../../stores';
import {
  IAudienceSaveMethods,
  IAudienceSetUpState,
  IAudienceState,
  ICaregiverFeatureState,
  IDependentTargetingSelectedState,
  IEnableSaveState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IFeatureSetElement,
  IGlobalFeatureState,
  IHcpFeatureState,
  ILoaderState,
  IMasterTargetingDDState,
  IUserInformationState,
} from '../../../../models/interfaces';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import {
  useAudienceSaveMethods,
  useAudienceSetUp,
  useAudienceSets,
  useCareGiverFeatureSet,
  useGlobalFeatureSet,
  useHcpFeatureSet,
} from '../../../../stores/audienceSets.store';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useNotificationMessage,
  usePostApiInterceptor,
  useRedirection,
} from '../../../../hooks';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import GlobalFeatureSet from './GlobalFeatureSet';
import CareGiverFeatureSet from './CareGiverFeatureSet';
import HcpFeatureSet from './HcpFeatureSet';

function separateFeatureSetsByTargetingId(data: any): {
  withTargetingId: any;
  withoutTargetingId: any;
  withCaregiver: any;
  withHcp: any;
} {
  const withTargetingId: any = [];
  const withoutTargetingId: any = [];
  let withCaregiver: any = null;
  let withHcp: any = null;
  data.forEach((featureSet: any) => {
    if (featureSet?.is_global) {
      withTargetingId.push(featureSet);
    } else if (featureSet.is_caregiver) {
      withCaregiver = featureSet;
    } else if (featureSet.is_hcp) {
      withHcp = featureSet;
    } else {
      withoutTargetingId.push(featureSet);
    }
  });

  return { withTargetingId, withoutTargetingId, withCaregiver, withHcp };
}

const TargetableFeatureSetup: FC<{
  selectedAudienceType: number | null;
  selectedAdditionalOpts: boolean;
  setIsClone: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAdditionalOpts: React.Dispatch<React.SetStateAction<boolean>>;
  setModalToOpenForCancel: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAudienceType: React.Dispatch<React.SetStateAction<number | null>>;
  setFocusOnTitle: React.Dispatch<React.SetStateAction<0 | 1 | 2>>;
  setMessageObj: React.Dispatch<React.SetStateAction<any>>;
}> = ({
  setIsClone,
  setMessageObj,
  setFocusOnTitle,
  selectedAudienceType,
  selectedAdditionalOpts,
  setSelectedAudienceType,
  setModalToOpenForCancel,
  setSelectedAdditionalOpts,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { ifUpdated, setIsInitiatedSaving, setIfUpdated } =
      useFeatureSetDataCheckStore((state: IFeatureSetDataCheckState) => state),
    {
      isEnableSaveForEachSet,
      setIsEnableSaveForEachSet,
      setIisFeatureSetsFilled,
      isFeatureSetsFilled,
    } = useCheckSavingEnabilityStore((state: IEnableSaveState) => state),
    {
      isDependentTargetingSelectedForEachSet,
      setIsDependentTargetingSelectedForEachSet,
    } = useCheckDependentTargetingSelectedStore(
      (state: IDependentTargetingSelectedState) => state
    ),
    {
      audienceTitle,
      audienceAgency,
      audienceBrand,
      advertiser,
      setAdvertiser,
      setAudienceAgency,
      setAudiencebrand,
      setAudienceTitle,
      setAudiencePageNumber,
    } = useAudienceSetUp((state: IAudienceSetUpState) => state),
    { isSaveInitiated, setIsSaveInitiated } = useAudienceSaveMethods(
      (state: IAudienceSaveMethods) => state
    ),
    {
      defineFeatureSet,
      setIsDataSaved,
      defineFeatureData,
      setDefineFeatureCount,
      selectedSavingOpt,
      isCancelled,
      setSelectedSavingOpt,
      setIsCancelled,
      setDefineFeatureData,
      firstFeatureNumber,
      setDefineFeatureSet,
      setFirstFeatureNumber,
      defineFeatureCount,
      definePrevId,
      setDefinePrevId,
    } = useAudienceSets((state: IAudienceState) => state),
    { updatedDataSet, savingDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { userInformation } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    { setShowBlankLoaderState } = useLoaderStore(
      (state: ILoaderState) => state
    ),
    {
      setCaregiverFeatureSet,
      setCaregiverTargeting,
      setCaregiverPrevId,
      caregiverPrevId,
    } = useCareGiverFeatureSet((state: ICaregiverFeatureState) => state),
    { setGlobalFeatureSet, setGlobalTargeting, globalPrevId, setGlobalPrevId } =
      useGlobalFeatureSet((state: IGlobalFeatureState) => state),
    { setHcpFeatureSet, setHcpTargeting, hcpPrevId, setHcpPrevId } =
      useHcpFeatureSet((state: IHcpFeatureState) => state),
    { targetingDDElements, setTargetingDDElementsForFs } =
      useMasterTargetingElementsStore(
        (state: IMasterTargetingDDState) => state
      );

  const {
    resetAllStates,
    redirectAudienceListing,
    redirectAudienceActivationSetup,
  } = useRedirection();

  const [selectedStatus, setSelectedStatus] = useState<number | null>(1),
    [prevSetId, setPrevSetId] = useState<number>(0),
    [deletingSetId, setDeletingSetId] = useState<number[] | null>(null),
    [audienceId, setAudienceId] = useState<number>(0),
    [body, setBody] = useState<any>(''),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [notificationObj, setNotificationObj] = useState<any>({ isShowing: false }),
    [globalFeatureSets, setGlobalFeatureSets] = useState<IFeatureSetElement>({
      setId: 0,
      orderId: 0,
    }),
    [hcpFeatureSet, setHcpFeaturesSet] = useState<IFeatureSetElement>({
      setId: 0,
      orderId: 0,
    }),
    [careGiverSet, setCareGiverSet] = useState<IFeatureSetElement>({
      setId: 0,
      orderId: 0,
    });

  const { context } = useNotificationMessage(notificationObj);

  useEffect(() => {
    if (isCancelled) {
      setDefineFeatureCount(0);
      setIsCancelled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancelled]);

  const featureSetBody = (feature_sets: IFeatureSetElement[]) => {
    const { withTargetingId, withoutTargetingId, withCaregiver, withHcp } =
      separateFeatureSetsByTargetingId(feature_sets);
    let newTargetingOptions: number[] = [];
    if (feature_sets?.length) {
      const newFeatureSets: IFeatureSetElement[] = [];
      let largestSetId = 0;
      withoutTargetingId
        .sort((a: any, b: any) => a.feature_set_rank - b.feature_set_rank)
        .forEach((el: any, index: number) => {
          newTargetingOptions.push(el.targeting[0].targeting_id);
          if (el.feature_set_id > largestSetId) {
            largestSetId = el.feature_set_id;
          }
          newFeatureSets.push({
            setId: el.feature_set_id,
            orderId: index + 1,
          });
        });
      setPrevSetId(largestSetId);
      setDefinePrevId(largestSetId);
      setDefineFeatureCount(withoutTargetingId.length);
      if (withTargetingId.length) {
        setGlobalPrevId(withTargetingId[0].feature_set_id);
        setGlobalFeatureSets({
          setId: withTargetingId[0].feature_set_id,
          orderId: 1,
          element: createGlobalElem(withTargetingId[0].feature_set_id, 1),
        });
      }
      setDefineFeatureData(
        createFeatureSet(newFeatureSets, withoutTargetingId.length)
      );
      if (withHcp && selectedAudienceType === 2) {
        setHcpPrevId(withHcp.feature_set_id);
        setHcpFeaturesSet({
          setId: withHcp.feature_set_id,
          orderId: 2,
          element: createHcpElem(withHcp.feature_set_id, 2),
        });
      }
      if (withCaregiver && selectedAdditionalOpts) {
        setCaregiverPrevId(withCaregiver.feature_set_id);
        setCareGiverSet({
          setId: withCaregiver.feature_set_id,
          orderId: withCaregiver.order_id,
          element: createCareGiverElem(
            withCaregiver.feature_set_id,
            withCaregiver.order_id
          ),
        });
      }
    }
    if (!withTargetingId?.length) {
      setGlobalFeatureSets({
        setId: 0,
        orderId: 1,
        element: createGlobalElem(0, 0),
      });
    }
    let newOptions = targetingDDElements.filter(
      (tar) =>
        !newTargetingOptions.includes(Number(tar.value)) &&
        !tar.is_hpa &&
        !tar.is_caregiver &&
        !tar.is_global
    );
    setTargetingDDElementsForFs(newOptions);
  };

  useEffect(() => {
    if (ifUpdated && Object.keys(updatedDataSet).length !== 0) {
      setShowBlankLoaderState(false);
      const { audience_id, status_id, feature_sets } = updatedDataSet;
      setAudienceId(audience_id);
      setSelectedStatus(status_id);
      if (updatedDataSet.feature_sets) {
        featureSetBody(feature_sets);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ifUpdated]);

  useEffect(() => {
    if (body) {
      setShowBlankLoaderState(true);
      setIsMounted(true);
      setNotificationObj({
        isShowing: true,
        type: 'success',
        messageContent: 'Audience Saved',
      });
    }
    body && setIsMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body]);

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.SAVE_FEATURE_SET_DATA,
    (data: any, error: any) => {
      setBody('');
      setIsMounted(false);
      setIsInitiatedSaving(false);
      setNotificationObj({ isShowing: false });
      setIsSaveInitiated(false);
      setShowBlankLoaderState(false);
      if (data && !error) {
        setIsDataSaved(true);
        eventAfterSave(data.audience_id);
      } else {
        updateErrorMessage('error', 'Error occurred while saving Audience.');
      }
    }
  );

  const updateErrorMessage = (type: string, messagecontent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messagecontent,
      duration: 5,
    });
  };

  useEffect(() => {
    if (
      !id &&
      defineFeatureCount > 0 &&
      defineFeatureCount > defineFeatureData.length &&
      !defineFeatureData
    ) {
      setDefineFeatureData(createFeatureSet());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defineFeatureCount]);

  const createFeatureSet = (fs?: IFeatureSetElement[], fsCount?: number) => {
    const tempFeatureSets: IFeatureSetElement[] = fs?.length
        ? [...fs]
        : [...defineFeatureData],
      newFeatureSets: IFeatureSetElement[] = [];
    for (let i = 0; i < (fsCount ?? defineFeatureCount); i++) {
      if (i < tempFeatureSets.length) {
        if (i === 0 && !firstFeatureNumber) {
          setFirstFeatureNumber(tempFeatureSets[i].orderId);
        }
        const { setId, orderId } = tempFeatureSets[i];
        newFeatureSets.push({
          setId: setId,
          orderId: orderId,
          element: createNewElem(setId, orderId, fsCount),
        });
      } else {
        if (selectedAdditionalOpts && defineFeatureCount < 0) {
          const newSetId = (definePrevId > 0 ? definePrevId : prevSetId) + 1;
          setPrevSetId(newSetId);
          setDefinePrevId(newSetId);
        } else {
          const newSetId = (definePrevId > 0 ? definePrevId : prevSetId) + 1;
          setPrevSetId(newSetId);
          setDefinePrevId(newSetId);
          newFeatureSets.push({
            setId: newSetId,
            orderId: i + 1,
            element: createNewElem(newSetId, i + 1),
          });
        }
      }
    }
    if (
      defineFeatureCount > (fsCount ?? defineFeatureCount) &&
      defineFeatureData?.length
    ) {
      const lastItem = defineFeatureData[defineFeatureData.length - 1];
      newFeatureSets.push({
        setId: lastItem.setId,
        orderId: lastItem.orderId,
        element: createNewElem(
          lastItem.setId,
          lastItem.orderId,
          defineFeatureCount
        ),
      });
    }
    return newFeatureSets;
  };

  useEffect(() => {
    if (deletingSetId?.length) {
      const tempIsEnableSaveForEachSet = { ...isEnableSaveForEachSet },
        tempIsDependentTargetingSelectedForEachSet = {
          ...isDependentTargetingSelectedForEachSet,
        };
      delete isFeatureSetsFilled[`${deletingSetId[0]}`];
      delete tempIsEnableSaveForEachSet[`${deletingSetId[0]}`];
      delete tempIsDependentTargetingSelectedForEachSet[`${deletingSetId[0]}`];

      setIsEnableSaveForEachSet(tempIsEnableSaveForEachSet);
      setIsDependentTargetingSelectedForEachSet(
        tempIsDependentTargetingSelectedForEachSet
      );
      setIisFeatureSetsFilled(isFeatureSetsFilled);
      delete defineFeatureSet[deletingSetId[0]];
      setDefineFeatureSet(defineFeatureSet);
      setDefineFeatureCount(defineFeatureCount - 1);
      const tempFeatureSets = defineFeatureData
        .filter((el: IFeatureSetElement) => !deletingSetId.includes(el.setId))
        .map((el: IFeatureSetElement, index: number) => {
          return {
            setId: el.setId,
            orderId: index + 1,
            element: createNewElem(el.setId, index + 1, defineFeatureCount - 1),
          };
        });

      setDefineFeatureData(tempFeatureSets);
      setDeletingSetId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletingSetId]);

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) {
      return;
    }

    const newItemsTemp = [...defineFeatureData];

    const [removed] = newItemsTemp.splice(source.index, 1);
    newItemsTemp.splice(destination.index, 0, removed);

    const newItems = newItemsTemp.map(
      (el: IFeatureSetElement, index: number) => {
        return {
          setId: el.setId,
          orderId: index + 1,
          element: createNewElem(el.setId, index + 1),
        };
      }
    );

    setDefineFeatureData(newItems);
  };

  const resetAllLocalStates = () => {
    setAudienceId(0);
    setSelectedAudienceType(null);
    setAudienceTitle('');
    setSelectedStatus(1);
    setSelectedAdditionalOpts(false);
    setDefineFeatureCount(0);
    setDefineFeatureData([]);
    setDefineFeatureSet({});
    setAudienceAgency(null);
    setAudiencebrand(null);
    setAdvertiser(null);
    setPrevSetId(0);
    setAudiencePageNumber(1);
    setHcpTargeting([]);
    setGlobalPrevId(null);
    setHcpPrevId(null);
    setCaregiverPrevId(null);
    setHcpFeatureSet({
      selectedProviderSpecialty: [],
      customNPIList: [],
      selectedProviderTaxonomy: [],
    });
    setCaregiverTargeting([]);
    setCaregiverFeatureSet({
      caregiverGender: null,
      caregiverAge: [],
      selectedGeographyDmas: [],
      selectedGeographyStates: [],
    });
    setGlobalTargeting([]);
    setGlobalFeatureSet({
      timeFrame: null,
      dateRange: null,
      patientGender: null,
      selectedAge: [],
      selectedRaceAndEthnicity: [],
      selectedGeographyDmas: [],
      selectedGeographyStates: [],
      language: [],
    });
    setDefinePrevId(0);
    setDeletingSetId(null);
    setTargetingDDElementsForFs([]);
    setModalToOpenForCancel(false);
  };

  useEffect(() => {
    const isEnableValues = Object.values(isEnableSaveForEachSet);
    if (
      isEnableValues?.length &&
      !isEnableValues.includes(1) &&
      isEnableValues.includes(2) &&
      isEnableValues.find((el) => el !== 0)
    ) {
      getOverAllData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savingDataSet]);

  useEffect(() => {
    const isEnableValues = Object.values(isEnableSaveForEachSet);
    if (
      !(defineFeatureData?.length && isEnableValues.find((el) => el !== 0)) &&
      !isEnableValues.includes(1) &&
      isSaveInitiated
    ) {
      getOverAllData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveInitiated]);

  useEffect(() => {
    if (defineFeatureData.length > 0 && defineFeatureCount > 0) {
      setDefineFeatureData(
        createFeatureSet(defineFeatureData, defineFeatureCount)
      );
    }
  }, []);

  // logic for the updated audience for hcp
  useEffect(() => {
    if (hcpPrevId) {
      setHcpFeaturesSet({
        setId: hcpPrevId,
        orderId: 2,
        element: createHcpElem(hcpPrevId, 2),
      });
    } else if (
      Object.keys(updatedDataSet).length === 0 &&
      selectedAudienceType === 2 &&
      !hcpPrevId
    ) {
      setHcpFeaturesSet({
        setId: 98,
        orderId: 98,
        element: createHcpElem(98, 98),
      });
    }
    setHcpPrevId(null);
  }, []);

  // logic for the updated audience for global
  useEffect(() => {
    if (globalPrevId) {
      setGlobalFeatureSets({
        setId: globalPrevId,
        orderId: 2,
        element: createGlobalElem(globalPrevId, 2),
      });
    }
    setHcpPrevId(null);
  }, []);

  // logic for the updated audience for caregiver
  useEffect(() => {
    if (caregiverPrevId && selectedAdditionalOpts) {
      setCareGiverSet({
        setId: caregiverPrevId,
        orderId: 2,
        element: createCareGiverElem(caregiverPrevId, 2),
      });
    }
    setCaregiverPrevId(null);
  }, []);

  useEffect(() => {
    if (Object.keys(updatedDataSet).length !== 0) {
      if (updatedDataSet.feature_sets.length > 0) {
        const { withCaregiver, withHcp } = separateFeatureSetsByTargetingId(
          updatedDataSet.feature_sets
        );
        if(!withCaregiver && selectedAdditionalOpts){
          setCareGiverSet({
            setId: 99,
            orderId: 99,
            element: createCareGiverElem(99, 99),
          });
        }
        if(!withHcp && selectedAudienceType === 2){
          setHcpFeaturesSet({
            setId: 98,
            orderId: 98,
            element: createHcpElem(98, 98),
          });
        }
      }
    }
  }, [selectedAudienceType]);

  // logic only for first time audience creation
  useEffect(() => {
    if (Object.keys(updatedDataSet).length === 0) {
      setGlobalFeatureSets({
        setId: 0,
        orderId: 0,
        element: createGlobalElem(0, 0),
      });
      if (selectedAudienceType === 2) {
        setHcpFeaturesSet({
          setId: 98,
          orderId: 98,
          element: createHcpElem(98, 98),
        });
      }
      if (selectedAdditionalOpts) {
        setCareGiverSet({
          setId: 99,
          orderId: 99,
          element: createCareGiverElem(99, 99),
        });
      }
    }
  }, []);

  const createCareGiverElem = (setId: number, orderId: number) => {
    return (
      <CareGiverFeatureSet
        featureSetNumber={setId}
        selectedAdditionalOpts={selectedAdditionalOpts}
      />
    );
  };

  const createHcpElem = (setId: number, orderId: number) => {
    return (
      <HcpFeatureSet featureSetNumber={setId} featureSetOrderNumber={orderId} />
    );
  };

  const createGlobalElem = (setId: number, orderId: number) => {
    return (
      <GlobalFeatureSet
        featureSetNumber={setId}
        featureSetOrderNumber={orderId}
        selectedAudienceType={selectedAudienceType}
      />
    );
  };

  const createNewElem = (
    setId: number,
    orderId: number,
    setCount: number | null = null
  ) => {
    return (
      <Draggable draggableId={`${setId}`} index={orderId - 1}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <DefinedFeatureSet
              featureSetNumber={setId}
              featureSetOrderNumber={orderId}
              setDeletingSetId={setDeletingSetId}
              dragHandleProps={provided.dragHandleProps}
            />
          </div>
        )}
      </Draggable>
    );
  };

  const getOverAllData = () => {
    const newFeatureSets = Object.values(savingDataSet),
      isEnableValues = Object.values(isEnableSaveForEachSet);
    if (
      (
        newFeatureSets?.length ||
        !(
          defineFeatureData?.length && isEnableValues.find((el) => el !== 0)
        )) &&
      !selectedAdditionalOpts &&
      isSaveInitiated
    ) {
      const featureSetDetails = newFeatureSets.filter(
        (el: any) => el.targeting?.length
      );
      const overAllData = {
        audience_id: audienceId,
        user_id: userInformation.user_id,
        audience_type_id: selectedAudienceType ?? 0,
        title: audienceTitle,
        agency_id: audienceAgency,
        brand_id: audienceBrand,
        advertiser_id: advertiser,
        status_id: selectedStatus,
        additional_option_id: selectedAdditionalOpts,
        feature_sets: featureSetDetails?.length ? featureSetDetails : [],
        is_edited: 0,
      };
      setBody(overAllData);
    } else if (
      (newFeatureSets?.length ||
        selectedAdditionalOpts) &&
      isSaveInitiated &&
      isEnableValues.find((el) => el !== 0)
    ) {
      const featureSetDetails = newFeatureSets.filter(
        (el: any) => el.targeting?.length
      );
      const overAllData = {
        audience_id: audienceId,
        user_id: userInformation.user_id,
        audience_type_id: selectedAudienceType ?? 0,
        title: audienceTitle,
        agency_id: audienceAgency,
        brand_id: audienceBrand,
        advertiser_id: advertiser,
        status_id: selectedStatus,
        additional_option_id: selectedAdditionalOpts,
        feature_sets: featureSetDetails?.length ? featureSetDetails : [],
        is_edited: 0,
      };
      setBody(overAllData);
    }
  };

  const resetIsDataSavedState = () => {
    setTimeout(() => {
      setIsDataSaved(false);
      setShowBlankLoaderState(false);
    }, 500);
  };

  const eventAfterSave = (audienceId: number) => {
    const tempDdElem = [...targetingDDElements],
      usableDdElem = tempDdElem.filter((el: any) => !el.is_global);

    usableDdElem.sort((a: any, b: any) => a.order_id - b.order_id);
    const usableSelectedSavingOpt = selectedSavingOpt;
    switch (usableSelectedSavingOpt) {
      case '1': {
        setShowBlankLoaderState(false);
        setIsDataSaved(false);
        resetAllLocalStates();
        setSelectedSavingOpt('');
        redirectAudienceListing();
        break;
      }
      case '2': {
        setIsClone(true);
        resetAllStates();
        setIfUpdated(false);
        resetAllLocalStates();
        resetIsDataSavedState();
        navigate('/create-audience');
        setFocusOnTitle(1);
        setSelectedSavingOpt('');
        break;
      }
      default:
        setShowBlankLoaderState(false);
        redirectAudienceActivationSetup(audienceId);
        setIsDataSaved(false);
        resetAllLocalStates();
        setSelectedSavingOpt('');
        setAudiencePageNumber(1);
        break;
    }
  };

  const handleAddFeature = () => {
    setDefineFeatureCount(defineFeatureCount + 1);
    setDefineFeatureData(createFeatureSet([], defineFeatureCount + 1));
  };

  return (
    <div className="featureSetWholeWrapBlock">
      {context}
      <div className="featureSetWhole">
        <div>{globalFeatureSets.element}</div>
        <div>{careGiverSet.element}</div>
        <div>{hcpFeatureSet.element}</div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" type="FEATURE_SET">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {defineFeatureCount > 0 &&
                  defineFeatureData.map((el: IFeatureSetElement) => (
                    <Fragment key={el.setId}>{el.element}</Fragment>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="addNewFeaturesetbtn">
        <Button
          onClick={handleAddFeature}
          className="secondaryBtn"
          disabled={defineFeatureCount >= 5}
        >
          <PlusOutlined />
          Add a FeatureSet
        </Button>
      </div>
    </div>
  );
};

export default TargetableFeatureSetup;
