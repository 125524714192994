import { Input, Modal } from 'antd';
import { FC, useState } from 'react';
import { CommonElementLabel } from '../../../sharedComponent';
import { CheckCircleTwoTone, CopyOutlined } from '@ant-design/icons';

const ImpressionPixelModal: FC<{
  isOpen: boolean;
  cancel: () => void;
  pixelFieldId: string | null;
  
}> = ({ isOpen, cancel, pixelFieldId }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false),
    [isCopiedImage, setIsCopiedImage] = useState<boolean>(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_IMPRESSION_PIXEL_URL}${pixelFieldId}`
    );
    setIsCopied(!isCopied);
  };

  const copyImage = () => {
    navigator.clipboard.writeText(
      `<img src=${process.env.REACT_APP_IMPRESSION_PIXEL_URL}${pixelFieldId} height='1px' width='1px' />`
    );
    setIsCopiedImage(!isCopiedImage);
  };

  return (
    <Modal
      centered
      open={isOpen}
      onCancel={cancel}
      okButtonProps={{
        disabled: pixelFieldId ? true : false,
      }}
      footer={null}
      className="commonModal impressionPixelModal"
      width={'950px'}
    >
      <div className="measuremntPixelSec">
        <div className="informationText">
          <CommonElementLabel label="Copy and paste either the Raw URL or Image Tag into the activation platform in order to track impressions. Only one option is needed." />
        </div>
        <div>
          <div className="mb-20">
            <CommonElementLabel label="Raw URL" specificWrapperClass='modalLabel'/>
            <Input
              placeholder="Raw URL"
              readOnly
              value={
                pixelFieldId
                  ? `${process.env.REACT_APP_IMPRESSION_PIXEL_URL}${pixelFieldId}`
                  : ''
              }
              suffix={
                <div onClick={handleCopy}>
                  {isCopied ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                  ) : (
                    <CopyOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  )}
                </div>
              }
            />
          </div>
          <div className="mb-20">
            <CommonElementLabel label="Image" specificWrapperClass='modalLabel'/>
            <Input
              placeholder="Image"
              readOnly
              value={
                pixelFieldId
                  ? `<img src=${process.env.REACT_APP_IMPRESSION_PIXEL_URL}${
                      pixelFieldId ?? ''
                    } height='1px' width='1px' />`
                  : ''
              }
              suffix={
                <div onClick={copyImage}>
                  {isCopiedImage ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                  ) : (
                    <CopyOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  )}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImpressionPixelModal;
