import { Col, Input, InputRef, Row } from 'antd';
import React, { Dispatch, FC, SetStateAction, useRef } from 'react';
import { CommonElementLabel, Single } from '../../../sharedComponent';
import { WarningOutlined } from '@ant-design/icons';
import { useCheckIfDataModifiedStore } from '../../../../stores';
import {
  ICheckIfDataModifiedState,
  IEachDropdownElementsType,
} from '../../../../models/interfaces';

const FeatureSetup: FC<{
  defaultTitle: string;
  setTitle: Dispatch<SetStateAction<string>>;
  defaultDomain: string;
  setDomain: Dispatch<SetStateAction<string>>;
  valid: boolean;
  agency: null | string;
  pixelId: null | string;
  setAgency: Dispatch<SetStateAction<string | null>>;
  brand: null | string;
  setBrand: Dispatch<SetStateAction<string | null>>;
  advertiser: null | string;
  setAdvertiser: Dispatch<SetStateAction<string | null>>;
  agencyDDElements: IEachDropdownElementsType[];
  advertiserDDElements: IEachDropdownElementsType[];
  brandDDElements: IEachDropdownElementsType[];
  setOrganisationType: Dispatch<SetStateAction<string | null>>;
  openOrganisation: Dispatch<SetStateAction<boolean>>;
}> = ({
  defaultTitle,
  setTitle,
  defaultDomain,
  setDomain,
  agency,
  setAgency,
  brand,
  setBrand,
  advertiser,
  setAdvertiser,
  valid,
  pixelId,
  agencyDDElements,
  advertiserDDElements,
  brandDDElements,
  setOrganisationType,
  openOrganisation
}) => {
  const { setIfDataModified } = useCheckIfDataModifiedStore(
    (state: ICheckIfDataModifiedState) => state
  );

  const titleRef = useRef<InputRef>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(e.target.value);
    setIfDataModified(true);
  };

  return (
    <div className="defaultFeatureSetSection">
      <Row gutter={24}>
        <Col span={12}>
          <div className="mb-20">
            <CommonElementLabel label="Title" isMandatory />
            <Input
              placeholder="Title"
              onChange={(e: any) => {
                setTitle(e?.target?.value);
                setIfDataModified(true);
              }}
              value={defaultTitle}
              ref={titleRef}
            />
          </div>
          <div className="mb-20">
            <CommonElementLabel label="Pixel Domain" isMandatory />
            <Input
              placeholder="Pixel Domain"
              onChange={handleChange}
              value={defaultDomain}
              status={valid ? 'error' : ''}
              required={true}
              suffix={
                valid && <WarningOutlined className="validationErrorIcon" />
              }
            />
          </div>
          {pixelId && (
            <div>
              <CommonElementLabel label="Pixel ID" isMandatory />
              <Input placeholder="Pixel ID" readOnly value={pixelId} />
            </div>
          )}
        </Col>
        <Col span={12}>
          <div className="mb-20">
           <div className="labelAndCTA d-flex">
           <CommonElementLabel label="Agency" isMandatory={true} />
            <button
              onClick={() => {
                setOrganisationType('agency');
                openOrganisation(true);
              }}
            >
              Add new
            </button>
           </div>
            <Single
              options={agencyDDElements}
              placeholder="Select Agency"
              onChange={(data: any) => {
                setAgency(data);
                setIfDataModified(true);
              }}
              isSearchable
              componentSpecificClasses={['checkboxListDropdownElem']}
              allowClear={false}
              props={{ value: agency }}
            />
          </div>
          <div className="mb-20">
            <div className="labelAndCTA d-flex">
            <CommonElementLabel label="Advertiser" isMandatory={true} />
            <button
              onClick={() => {
                setOrganisationType('advertiser');
                openOrganisation(true);
              }}
            >
              Add new
            </button>
            </div>
            <Single
              options={advertiserDDElements}
              placeholder="Select Advertiser"
              onChange={(data: any) => {
                setIfDataModified(true);
                setAdvertiser(data);
              }}
              isSearchable
              componentSpecificClasses={['checkboxListDropdownElem']}
              allowClear={false}
              props={{ value: advertiser }}
            />
          </div>
          <div className="mb-20">
            <div className="labelAndCTA d-flex">
            <CommonElementLabel label="Brand" isMandatory={true} />
            <button
              onClick={() => {
                setOrganisationType('brand');
                openOrganisation(true);
              }}
            >
              Add new
            </button>
            </div>
            <Single
              options={brandDDElements}
              placeholder="Select Brand"
              onChange={(data: any) => {
                setBrand(data);
                setIfDataModified(true);
              }}
              isSearchable
              componentSpecificClasses={['checkboxListDropdownElem']}
              allowClear={false}
              props={{ value: brand }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FeatureSetup;
