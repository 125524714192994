import { Button, InputRef, Modal } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';
import { InputField } from '../../../sharedComponent';

const OrganisationModal: FC<{
  type: 'brand' | 'agency' | 'advertiser';
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  onSave: (value: string) => void;
  value: string;
  setVal: React.Dispatch<React.SetStateAction<string>>;
  setId: React.Dispatch<React.SetStateAction<number>>;
}> = ({ type, isOpen, onClose, onSave, value, setVal, setId }) => {
  const [fieldData, setFieldData] = useState<string>(value.length ? value : '');
  const nameRef = useRef<InputRef>(null);

  const handleCancel = () => {
    setFieldData('');
    setId(0);
    setVal('');
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        nameRef.current?.focus({
          cursor: 'end',
        });
      }, 300);
    }
  }, [isOpen]);

  const handleSave = () => {
    onSave(fieldData || '');
    setFieldData('');
    onClose();
  };

  function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <Modal
      centered
      open={isOpen}
      wrapClassName="genericAddingRecordModal"
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          disabled={!fieldData}
          key="submit"
          type="primary"
          onClick={handleSave}
        >
          Save
        </Button>,
      ]}
      className="commonModal"
      width="770px"
    >
      <h3>{capitalizeFirstLetter(type)}</h3>
      <InputField
        label="Name"
        props={{ value: fieldData, ref: nameRef }}
        inputPlaceholder={`${capitalizeFirstLetter(type)}`}
        onChange={(e: any) => setFieldData(e)}
      />
    </Modal>
  );
};

export default OrganisationModal;
