import { Table, TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { FC } from 'react';
dayjs.extend(utc);

interface DataType {
  key: number;
  Timestamp: string;
  UserAgent: string;
  IP: string;
}

const PixelFires: FC<{
  isLoading: boolean;
  data: any;
}> = ({ isLoading, data }) => {

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Timestamp',
      dataIndex: 'Timestamp',
      key: 'Timestamp',
      render: (text) => dayjs.utc(text).local().format('M/D/YYYY HH:mm:ss A'),
      width: '150px',
      ellipsis: true,
    },
    {
      title: 'Useragent',
      dataIndex: 'IP',
      key: 'IP',
      width: '400px',
      ellipsis: true,
    },
    {
      title: 'IP Address',
      dataIndex: 'UserAgent',
      key: 'UserAgent',
      width: '100px',
      ellipsis: true,
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        onChange={() => {}}
        className={`antTableElement audienceListTable${
          isLoading ? ' hideNoDataLabel' : ''
        }`}
        pagination={false}
        loading={isLoading}
        sortDirections={['ascend', 'descend', 'ascend']}
      />
    </div>
  );
};

export default PixelFires;
